import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import '../../Css/ClassData.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function ClassData() {
    const location = useLocation();
    const { selectedClass } = location.state || { selectedClass: 'Unknown Class' };
    const isMounted = useRef(true);

    const [students, setStudents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const [promoteClass, setPromoteClass] = useState("");
    const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
    const [selectedStudentToModify, setSelectedStudentToModify] = useState('');

    const initialStudentState = {
        rollNo: '',
        name: '',
        phone: '',
        parentName: '',
        gender: ''
    };

    const [newStudent, setNewStudent] = useState(initialStudentState);
    const [modifiedStudent, setModifiedStudent] = useState(initialStudentState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const filteredStudents = students[selectedClass]?.filter(student =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.roll_number.includes(searchQuery)
    ) || [];

    const validateStudentData = (data) => {
        if (!data.name || data.name.trim().length < 2) {
            showToast("Please enter a valid name (minimum 2 characters)", true);
            return false;
        }
        if (!data.phone || !/^\d{10}$/.test(data.phone)) {
            showToast("Please enter a valid 10-digit phone number", true);
            return false;
        }
        if (!data.rollNo || data.rollNo.trim() === '') {
            showToast("Please enter a valid roll number", true);
            return false;
        }
        if (!data.parentName || data.parentName.trim().length < 2) {
            showToast("Please enter a valid parent name", true);
            return false;
        }
        if (!data.gender) {
            showToast("Please select a gender", true);
            return false;
        }
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewStudent({ ...newStudent, [name]: value });
    };

    const handleModifyInputChange = (e) => {
        const { name, value } = e.target;
        setModifiedStudent({ ...modifiedStudent, [name]: value });
    };

    const handleStudentSelect = (e) => {
        const selectedRollNo = e.target.value;
        const selectedStudent = students[selectedClass]?.find(s => s.uniqueCode === selectedRollNo);
        if (selectedStudent) {
            setSelectedStudentToModify(selectedRollNo);
            setModifiedStudent({
                rollNo: selectedStudent.roll_number,
                name: selectedStudent.name,
                phone: selectedStudent.phone,
                parentName: selectedStudent.parent_name,
                gender: selectedStudent.gender,
                grade: selectedStudent.standard
            });
        }
    };

    const handleModifyStudent = async (e) => {
        e.preventDefault();

        if (!validateStudentData(modifiedStudent)) {
            return;
        }

        try {
            await axiosInstance.put(`/manage-student/modify-student/${selectedStudentToModify}`, {
                name: modifiedStudent.name.trim(),
                standard: modifiedStudent.grade.trim(),
                roll_number: modifiedStudent.rollNo.trim(),
                parent_name: modifiedStudent.parentName.trim(),
                phone: modifiedStudent.phone,
                gender: modifiedStudent.gender,
            });

            showToast('Student modified successfully');
            setIsModifyModalOpen(false);
            setSelectedStudentToModify('');
            setModifiedStudent(initialStudentState);
            fetchStudents();

        } catch (error) {
            console.error('Error modifying student:', error);
            showToast(error.response?.data?.detail || 'Failed to modify student', true);
        }
    };

    const handleAddStudent = async (e) => {
        e.preventDefault();

        if (!validateStudentData(newStudent)) {
            return;
        }

        try {
            await axiosInstance.post('/manage-student/create-student', {
                name: newStudent.name.trim(),
                standard: selectedClass.match(/\d+/)[0],
                roll_number: newStudent.rollNo.trim(),
                parent_name: newStudent.parentName.trim(),
                phone: newStudent.phone,
                gender: newStudent.gender,
            });

            showToast('Student created successfully');
            setNewStudent(initialStudentState);
            setIsModalOpen(false);
            fetchStudents();

        } catch (error) {
            console.error('Error creating student:', error);
            showToast(error.response?.data?.detail || 'Failed to create student', true);
        }
    };

    const handleSelect = (rollNo) => {
        setSelectedStudents(prev =>
            prev.includes(rollNo)
                ? prev.filter(id => id !== rollNo)
                : [...prev, rollNo]
        );
    };

    const handleSelectAll = (e) => {
        setSelectedStudents(e.target.checked
            ? filteredStudents.map(student => student.roll_number)
            : []
        );
    };

    const isAllSelected = filteredStudents.length > 0 &&
        selectedStudents.length === filteredStudents.length;

    const handleRemoveSelected = async () => {
        if (!selectedStudents.length) {
            showToast('Please select students to remove', true);
            return;
        }

        try {
            await axiosInstance.post(`/manage-student/delete-students?standard=${selectedClass.match(/\d+/)[0]}`, {
                roll_numbers: selectedStudents
            });

            showToast('Students removed successfully');
            fetchStudents();
            setSelectedStudents([]);

        } catch (error) {
            console.error('Error removing students:', error);
            showToast(error.response?.data?.detail || 'Failed to remove students', true);
        }
    };

    const fetchStudents = async () => {
        try {
            const response = await axiosInstance.get(`/manage-student/students-by-class?standard=${selectedClass}`);
            setStudents(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching students:', error);
            showToast('Failed to fetch students data', true);
        }
    };

    useEffect(() => {
        fetchStudents();
    }, [selectedClass]);

    const handlePromoteStudents = async (e) => {
        e.preventDefault();

        if (!promoteClass) {
            showToast('Please enter a valid class to promote to', true);
            return;
        }

        if (!selectedStudents.length) {
            showToast('Please select students to promote', true);
            return;
        }

        try {
            await axiosInstance.post(`/manage-student/promote-students?standard=${promoteClass}&fromClass=${selectedClass.match(/\d+/)[0]}`, {
                roll_numbers: selectedStudents
            });

            showToast('Students promoted successfully');
            fetchStudents();
            setSelectedStudents([]);
            setIsPromoteModalOpen(false);
            setPromoteClass('');

        } catch (error) {
            console.error('Error promoting students:', error);
            showToast(error.response?.data?.detail || 'Failed to promote students', true);
        }
    };

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name')}</span>
                    <p className="text">Welcome back to {selectedClass}!</p>
                </div>

                <div className="cls-button">
                    <button className="cls-class-btn">{selectedClass}</button>
                    {selectedClass.match(/\d+/)[0] === localStorage.getItem('cteacher') && (
                        <>
                            <button className="cls-add-btn" onClick={() => setIsModalOpen(true)}>+ Add Students</button>
                            <button className="cls-add-btn" onClick={() => setIsModifyModalOpen(true)}>Modify Student</button>
                            <button
                                className="cls-remove-btn"
                                onClick={handleRemoveSelected}
                                disabled={selectedStudents.length === 0}
                            >
                                - Remove Selected
                            </button>
                        </>
                    )}
                    {selectedClass.match(/\d+/)[0] === localStorage.getItem('cteacher') && (
                        <button
                            className="cls-class-btn"
                            onClick={() => setIsPromoteModalOpen(true)}
                            disabled={selectedStudents.length === 0}
                        >
                            Promote Students
                        </button>
                    )}
                </div>

                <div className="students">
                    <div className="te-cls-stu-inner">
                        <h5>Your Students</h5>
                    </div>
                    <input
                        type="text"
                        placeholder="Search students by name or roll number..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-box"
                    />
                    <div className="table-block">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={handleSelectAll}
                                            checked={isAllSelected}
                                        />
                                    </th>
                                    <th>Roll.no</th>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th>Grade</th>
                                    <th>Parent Name</th>
                                    <th>Gender</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map((student, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedStudents.includes(student.roll_number)}
                                                onChange={() => handleSelect(student.roll_number)}
                                            />
                                        </td>
                                        <td>
                                            {selectedClass.match(/\d+/)[0] === localStorage.getItem('cteacher') ? (
                                                <Link
                                                    to={`/teacher/student?uniqueCode=${student.uniqueCode}`}
                                                    className="link-row"
                                                    style={{
                                                        color: '#3366ff',
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                        fontWeight: 500,
                                                        ':hover': {
                                                            textDecoration: 'underline'
                                                        }
                                                    }}
                                                >
                                                    {student.roll_number}
                                                </Link>
                                            ) : (
                                                <span>{student.roll_number}</span>
                                            )}
                                        </td>
                                        <td>{student.name}</td>
                                        <td>{student.phone}</td>
                                        <td>{student.standard}</td>
                                        <td>{student.parent_name}</td>
                                        <td>{student.gender}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {filteredStudents.length === 0 && <p>No students found.</p>}
                </div>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Add New Student</h3>
                        <form onSubmit={handleAddStudent}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={newStudent.name}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="grade"
                                placeholder="Standard"
                                value={selectedClass.match(/\d+/)[0]}
                                disabled
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone Number"
                                value={newStudent.phone}
                                onChange={handleInputChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                maxLength={10}
                                required
                            />
                            <input
                                type="text"
                                name="rollNo"
                                placeholder="Roll No"
                                value={newStudent.rollNo}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="parentName"
                                placeholder="Parent Name"
                                value={newStudent.parentName}
                                onChange={handleInputChange}
                                required
                            />
                            <select
                                name="gender"
                                value={newStudent.gender}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>

                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        setNewStudent(initialStudentState);
                                    }}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="save-btn">Add Student</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isModifyModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Modify Student</h3>
                        <form onSubmit={handleModifyStudent}>
                            <select
                                value={selectedStudentToModify}
                                onChange={handleStudentSelect}
                                required
                            >
                                <option value="">Select Student</option>
                                {students[selectedClass]?.map(student => (
                                    <option key={student.roll_number} value={student.uniqueCode}>
                                        {student.name} ({student.roll_number})
                                    </option>
                                ))}
                            </select>
                            {selectedStudentToModify && (
                                <>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={modifiedStudent.name}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="grade"
                                        placeholder="Standard"
                                        value={modifiedStudent.grade}
                                        onChange={handleModifyInputChange}
                                    />
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone Number"
                                        value={modifiedStudent.phone}
                                        onChange={handleModifyInputChange}
                                        onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                        maxLength={10}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="rollNo"
                                        placeholder="Roll No"
                                        value={modifiedStudent.rollNo}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="parentName"
                                        placeholder="Parent Name"
                                        value={modifiedStudent.parentName}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <select
                                        name="gender"
                                        value={modifiedStudent.gender}
                                        onChange={handleModifyInputChange}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </>
                            )}
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsModifyModalOpen(false);
                                        setSelectedStudentToModify('');
                                        setModifiedStudent(initialStudentState);
                                    }}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="save-btn"
                                    disabled={!selectedStudentToModify}
                                >
                                    Modify Student
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isPromoteModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Promoting Students</h3>
                        <form onSubmit={handlePromoteStudents}>
                            <input
                                type="text"
                                name="Class"
                                placeholder="Class"
                                value={promoteClass}
                                onChange={(e) => setPromoteClass(e.target.value)}
                                required
                            />
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsPromoteModalOpen(false);
                                        setPromoteClass('');
                                    }}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="save-btn">Promote Student</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default ClassData;
