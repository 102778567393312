import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../../Css/Navbar.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const [isMenuOpen, setIsMenuOpen] = useState(false);



    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
        setPassword('');
        setConfirmPassword('');
        setErrors({ password: '', confirmPassword: '' });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { password: '', confirmPassword: '' };

        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
            isValid = false;
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password';
            isValid = false;
        } else if (password !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast('Please fix the validation errors', true);
            return;
        }

        try {
            await axiosInstance.post('/teacher/reset-password', {
                password: password.trim()
            });

            showToast('Password reset successfully');
            handleModal();
        } catch (error) {
            console.error('Error resetting password:', error);
            const errorMessage = error.response?.data?.message || 'Failed to reset password. Please try again.';
            showToast(errorMessage, true);
        }
    };

    const handleLogout = () => {
        try {
            localStorage.clear();
            window.location.href = '/login';
        } catch (error) {
            console.error('Logout error:', error);
            showToast('Error during logout. Please try again.', true);
        }
    };

    return (
        <header className="navbar neu">
            <nav className="navigation">
                <Link to="/teacher/home" style={{ textDecoration: 'none' }}>
                    <h1 className="app-title">
                        <span style={{ fontSize: '70px', color: " #352f2f" }}>E</span>
                        <span style={{ fontSize: '60px', color: "rgba(53, 47, 47, 0.67)" }}>xams</span>
                    </h1>
                </Link>
                <button className="hamburger" onClick={toggleMenu}>
                    {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
                <ol className={`menu ${isMenuOpen ? 'open' : ''}`}>
                    <li className={`menu-items ${location.pathname === '/teacher/home' ? 'active' : ''}`}>
                        <Link to="/teacher/home" onClick={() => setIsMenuOpen(false)}>Home</Link>
                    </li>
                    <li className={`menu-items ${location.pathname === '/teacher/my-exams' ? 'active' : ''}`}>
                        <Link to="/teacher/my-exams" onClick={() => setIsMenuOpen(false)}>Exams</Link>
                    </li>
                    <li className="menu-items">
                        <Link onClick={() => { handleModal(); setIsMenuOpen(false); }}>Reset Password</Link>
                    </li>
                    <li className="menu-items logout-item">
                        <button className="logout-button" onClick={handleLogout}>
                            <LogoutIcon style={{ fontSize: '15px' }} /> Log out
                        </button>
                    </li>
                </ol>
            </nav>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Reset Password</h3>
                        <form onSubmit={handleResetPassword}>
                            <div className="password-container-1">
                                <input
                                    className='input-1'
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="New Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <span
                                    className="toggle-password"
                                    onClick={()=> setShowPassword(!showPassword)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </span>
                            </div>
                            {errors.password && <p className="error-text">{errors.password}</p>}

                            <div className="password-container-1">
                                <input
                                    className='input-1'
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    placeholder="Confirm New Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <span
                                    className="toggle-password"
                                    onClick={()=> setShowPassword(!showPassword)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </span>
                            </div>
                            {errors.confirmPassword && <p className="error-text">{errors.confirmPassword}</p>}

                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={handleModal}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="save-btn">Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </header>
    );
}

export default Navbar;
