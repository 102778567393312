import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import '../../Css/StudentHome.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ExamCard from './StartExamCard';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Home() {
    const [searchQuery, setSearchQuery] = useState('');
    const [studentInfo, setStudentInfo] = useState({
        student_name: '',
        exam_stats: {
            total_exams: 0,
            attended_exams: 0,
            running_exams: 0,
            upcoming_exams: 0
        },
        exam_details: []
    });

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    useEffect(() => {
        let isMounted = true;

        const fetchStudentInfo = async () => {
            try {
                const response = await axiosInstance.get('/student/student-home');
                if (!response?.data) {
                    throw new Error('No data received from server');
                }
                
                if (isMounted) {
                    localStorage.setItem('name', response.data.student_name);
                    setStudentInfo(response.data);
                    console.log(response.data);
                    showToast('Student information loaded successfully');
                }
            } catch (error) {
                console.error('Error fetching student info:', error);
                if (isMounted) {
                    showToast(
                        error.response?.data?.message || 'Failed to load student information',
                        true
                    );
                }
            }
        };

        fetchStudentInfo();

        return () => {
            isMounted = false;
        };
    }, []);

    const stats = [
        { id: 1, label: 'Total Exams', value: studentInfo.exam_stats.total_exams, icon: <PersonOutlineOutlinedIcon style={{ color: '#007bff' }} /> },
        { id: 2, label: 'Exams Attended', value: studentInfo.exam_stats.attended_exams, icon: <FlagOutlinedIcon style={{ color: '#28a745' }} /> },
        { id: 3, label: 'Running Exams', value: studentInfo.exam_stats.running_exams, icon: <AssignmentOutlinedIcon style={{ color: '#ffc107' }} /> },
        { id: 4, label: 'Upcoming Exams', value: studentInfo.exam_stats.upcoming_exams, icon: <EventOutlinedIcon style={{ color: '#dc3545' }} /> }
    ];

    const handleSearchChange = (e) => {
        const value = e.target.value.trim();
        setSearchQuery(value);
    };

    // Filter exams based on the search query
    const filteredExams = studentInfo.exam_details.filter((exam) =>
        exam.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const renderExamCards = () => {
        if (!filteredExams.length) {
            return <p className="no-exams-message">No Active Exams Found</p>;
        }

        return filteredExams.map((exam) => (
            <ExamCard 
                key={exam.exam_id} 
                date={exam.exam_date}
                time={exam.start_time}
                subject={exam.subject}
                examType={exam.status}
                exam_id={exam.exam_id}
                stats={{
                    totalQuestions: exam.total_questions || 0,
                    timeLimit: exam.duration || 0
                }}
            />
        ));
    };

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div className="welcome-section">
                    <div>
                        <span className="welcome-name">
                            Hello, {studentInfo.student_name || 'Student'}
                        </span>
                        <p className="text">Welcome back to Exams!</p>
                    </div>
                    <div className="search-section">
                        <input
                            type="text"
                            className="search-bar"
                            placeholder="Search Exams..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            aria-label="Search exams"
                        />
                    </div>
                </div>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '1.5em' }}>My Class</h3>
                    <div className="class-buttons">
                        <button 
                            className="cls-btn" 
                            style={{ backgroundColor: "#C2D9F6" }}
                            aria-label="Class 5th"
                        >
                            Class {studentInfo.standard}
                        </button>
                    </div>
                </section>
                <section className="overview-sction">
                    <span className="over">Overview of your exams and students</span>
                    <div className="stu-container">
                        {stats.map((stat) => (
                            <div key={stat.id} className="stu-card">
                                <div className="stu-icon">
                                    <div className="stu-circle-ico">
                                        {stat.icon}
                                    </div>
                                </div>
                                <div className="stu-space">
                                    <div className="stu-value">{stat.value}</div>
                                    <div className="stu-label">{stat.label}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <div className="exam-list">
                    {renderExamCards()}
                </div>
            </div>
        </>
    );
}

export default Home;
