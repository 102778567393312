import React, { useState, useEffect, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EventNoteIcon from '@mui/icons-material/EventNote';
import QuizIcon from '@mui/icons-material/Quiz';

import Navbar from './Navbar';
import '../../Css/StudentData.css';

const TStudentData = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('uniqueCode');
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const showToast = (message, isError = false) => {
    // if (isMounted.current) {
      Toastify({
        text: message,
        duration: 3000,
        gravity: "top",
        position: "right",
        close: true,
        style: {
          background: isError 
            ? "linear-gradient(to right, #ff0000, #ff5f6d)"
            : "linear-gradient(to right, #00b09b, #96c93d)",
        }
      }).showToast();
    // }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (!username) {
          throw new Error('Student unique code is required');
        }

        const response = await axiosInstance.get(`/manage-student/details/${username}`);
        
        if (!response?.data) {
          throw new Error('No data received from server');
        }
        setLoading(false);
        setStudentData(response.data);
        showToast('Student data loaded successfully');

        if (isMounted.current) {
          
        }
      } catch (error) {
        console.error('Error fetching student data:', error);
        if (isMounted.current) {
          showToast(error.response?.data?.message || error.message, true);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    fetchStudentData();
  }, [username]);

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (!studentData) {
    return <div className="no-data">No student data found</div>;
  }

  const { student_info, statistics, exams } = studentData;

  return (
    <>
      <Navbar />
      <div className="teacher-home">
        <div className="hero">
          <div className="backarrow">
            <Link to="/principal/class-data">
              <KeyboardBackspaceIcon className="back-icon" />
            </Link>
          </div>
          <div className="teacher-name-status">
            <h2 className="teacher-name">{student_info?.name?.toUpperCase() || 'N/A'}</h2>
            <div className="status">
              <CircleIcon className="status-icon" />
              <span className="status-text">ACTIVE</span>
            </div>
          </div>
          <div className="vertical"></div>
          <div className="">
            <span className="label">uniqueCode</span>
            <h2 className="username">{student_info?.username || 'N/A'}</h2>
          </div>
        </div>

        <div className="stu-details-block">
          <div className="stu-left-details">
            <div className="stu-personal-details">
              <h2 className="stu-section-header">
                <PersonIcon />
                PERSONAL INFORMATION
              </h2>
              <div className="info-row">
                <div className="stu-info">
                  <div className="stu-pd">
                    <span>ROLL NUMBER</span>
                    <h5>{student_info?.roll_number || 'N/A'}</h5>
                  </div>
                  <div className="stu-pd">
                    <span>CLASS</span>
                    <h5>{student_info?.standard || 'N/A'}</h5>
                  </div>
                </div>
                <div className="stu-info">
                  <div className="stu-pd">
                    <span>GENDER</span>
                    <h5>{student_info?.gender || 'N/A'}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="stu-right-details">
            <div className="stu-contact-details">
              <h2 className="stu-section-header">
                <ContactMailIcon />
                CONTACT INFORMATION
              </h2>
              <div className="stu-info">
                <div className="stu-pd">
                  <span>PARENT NAME</span>
                  <h5>{student_info?.parent_name || 'N/A'}</h5>
                </div>
                <div className="stu-pd">
                  <span>PHONE</span>
                  <h5>{student_info?.phone || 'N/A'}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stu-academic-overview">
          <h2 className="stu-section-header">
            <EventNoteIcon />
            ACADEMIC OVERVIEW
          </h2>
          <div className="stu-overview-cards">
            <div className="stu-overview-card">
              <span>TOTAL EXAMS</span>
              <h3>{statistics?.total_exams || 0}</h3>
            </div>
            <div className="stu-overview-card passed">
              <span>PASSED</span>
              <h3>{statistics?.passed_exams || 0}</h3>
            </div>
            <div className="stu-overview-card failed">
              <span>FAILED</span>
              <h3>{statistics?.failed_exams || 0}</h3>
            </div>
            <div className="stu-overview-card average-grade">
              <span>AVERAGE GRADE</span>
              <h3>{statistics?.average_grade || 'N/A'}</h3>
            </div>
          </div>
        </div>

        <div className="stu-exam-history">
          <h2 className="stu-section-header">
            <QuizIcon />
            EXAM HISTORY
          </h2>
          <div className="stu-exam-scroll">
            {exams?.length > 0 ? (
              exams.map((exam) => (
                <Link 
                  key={exam.exam_id}
                  to={`/teacher/student-exam-info?exam_id=${exam.exam_id}&uniqueCode=${student_info.username}`} 
                  style={{textDecoration:"none", color:"black"}}
                >
                  <div className="stu-exam-card">
                    <div className="exam-header">
                      <h4>{exam.subject?.toUpperCase() || 'N/A'}</h4>
                      <span className={`status ${exam.status?.toLowerCase()}`}>
                        {exam.status || 'N/A'}
                      </span>
                    </div>
                    <div className="stu-exam-details">
                      <div className='ttt'>
                        <span>DATE: {exam.scheduled_date || 'N/A'}</span>
                        <span>MARKS: {exam.results?.[username] || 'N/A'}</span>
                      </div>
                      <span>TIME: {exam.scheduled_time || 'N/A'}</span>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div>No exam history available</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TStudentData;