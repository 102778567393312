import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import exam_card_icon from '../../Images/exam_card_icon.jpg';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

const ExamCard = ({ data }) => {
    console.log(data)
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const deleteExam = async (exam_id) => {
        try {
            await axiosInstance.delete(`/exam/delete-exam/${exam_id}`);
            showToast('Exam deleted successfully');
            window.location.replace('/teacher/my-exams');
        } catch (error) {
            console.error(`Failed to delete exam with ID ${exam_id}:`, error);
            showToast('Failed to delete exam', true);
        }
    };

    const completeExam = async (exam_id) => {
        try {
            await axiosInstance.get(`/exam/complete-exam/${exam_id}`);
            showToast('Exam marked as complete');
            window.location.replace('/teacher/my-exams');
        } catch (error) {
            console.error(`Failed to complete exam with ID ${exam_id}:`, error);
            showToast('Failed to complete exam', true);
        }
    };

    const validateExamData = () => {
        if (!date) {
            showToast('Please select a date', true);
            return false;
        }
        if (!time) {
            showToast('Please select a time', true);
            return false;
        }
        if (!duration || isNaN(duration)) {
            showToast('Please enter a valid duration', true);
            return false;
        }
        return true;
    };

    const updateExam = async (e) => {
        e.preventDefault();
        
        if (!validateExamData()) {
            return;
        }

        try {
            await axiosInstance.patch(`/exam/update-exam/${data.exam_id}`, {
                scheduled_date: date,
                scheduled_time: time,
                duration: duration
            });
            showToast('Exam updated successfully');
            setIsModalOpen(false);
            window.location.replace('/teacher/my-exams');
        } catch (err) {
            console.error('Error updating exam:', err);
            showToast(err.response?.data?.detail || 'Failed to update exam', true);
        }
    };

    const resetForm = () => {
        setDate('');
        setTime('');
        setDuration('');
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="exxam-card">
                <div className="card-header">
                    <p>Date: {data.date || 'Not specified'}</p>
                    <span className={`status ${data.status?.toLowerCase()}`}>
                        {data.status || 'Unknown'}
                    </span>
                </div>
                <div className="card-body">
                    <div className='sub'>
                        <div className="subject-info">
                            <div className="subject-icon">
                                <img
                                    src={exam_card_icon}
                                    alt={`${data.subject} Icon`}
                                    onError={() => showToast('Failed to load subject icon', true)}
                                />
                            </div>
                            <div>
                                <h3>{data.subject || 'Unknown Subject'}</h3>
                                <p>{data.examType || 'Not specified'}</p>
                            </div>
                        </div>
                        {data.status !== 'completed' && (
                            <button className="view-more-btn" onClick={() => setIsModalOpen(true)}>
                                <EditIcon fontSize='small'/> Edit
                            </button>
                        )}
                        <button 
                            className="view-more-btn" 
                            onClick={() => deleteExam(data.exam_id)}
                            style={{background:"red", color:"white"}}
                        >
                            <DeleteIcon fontSize='small'/> Delete
                        </button>
                        <button className='view-more-btn' onClick={() => completeExam(data.exam_id)}>
                            Complete
                        </button>
                        <Link to={`/teacher/student-exam-data?exam_id=${data.exam_id}`}>
                            <button className="view-more-btn">View more</button>
                        </Link>
                    </div>
                    <hr style={{ border: "1px solid #000", margin: "20px 0" }} />
                    <div className="stats">
                        <div className="stat-item">
                            <p>{data.stats?.totalStudents || 0}</p>
                            <span>Total students</span>
                        </div>
                        <div className="stat-item">
                            <p>{data.stats?.attended || 0}</p>
                            <span>Total attended</span>
                            <div className="vertical-line"></div>
                        </div>
                        <div className="stat-item">
                            <p>{data.stats?.highestMarks || 0}</p>
                            <span>Highest marks</span>
                            <div className="vertical-line"></div>
                        </div>
                        <div className="stat-item">
                            <p>{data.Class || 'N/A'}</p>
                            <span>Class</span>
                            <div className="vertical-line"></div>
                        </div>
                    </div>
                </div>
            </div>
            
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Edit Exam</h3>
                        <form onSubmit={updateExam}>
                            <input
                                type="date"
                                name="date"
                                placeholder="Select Date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                            <input
                                type="time"
                                name="time"
                                placeholder="Select Time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                            <input
                                type="number"
                                name="duration"
                                placeholder="Enter the Duration of the Exam (minutes)"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                min="1"
                                required
                            />
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={resetForm}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="save-btn">
                                    Update Exam
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default ExamCard;