import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Components/Teacher/Home';
import Login from './Components/Teacher/Login';
import MyExams from './Components/Teacher/MyExams';
import StudentExamData from './Components/Teacher/StudentExamData';
import ClassData from './Components/Teacher/ClassData';
import GenerateExam from './Components/Teacher/GenerateExam';
import GenerateExamWithAi from './Components/Teacher/GenerateExamWithAi';
import StudentHome from './Components/Student/Home';
import StudentLogin from './Components/Student/Login';
import StudentExam from './Components/Student/Exam';
import StudentMyExams from './Components/Student/MyExams';
import PrincipalHome from './Components/Principal/Home';
import PrincipalMyExams from './Components/Principal/MyExams';
import PrincipalTeacherData from './Components/Principal/TeachersData';
import PrincipalCLassData from './Components/Principal/CLassData';
import PrincipalExamData from './Components/Principal/ExamData';  
import PrincipalGenerateExam from './Components/Principal/GenerateExam';  
import PrincipalGenerateExamWithAi from './Components/Principal/GenerateExamWithAi';  
import TeacherData from './Components/Principal/TeacherData';
import StudentData from './Components/Principal/StudentData';
import TStudentData from './Components/Teacher/StudentData';
import ExamData from './Components/Student/ExamData';
import TExamData from './Components/Teacher/ExamData';
// import axiosInstance from './axiosInstance';

// New PrivateRoute component for validation
const PrivateRoute = ({ element, allowedRoles }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    if (token && userRole === "principal") {
      return <Navigate to="/principal/home" />;
    }
    if (token && userRole === "student") {
      return <Navigate to="/student/home" />;
    }
    if (token && userRole === "teacher") {
      return <Navigate to="/teacher/home" />;
    }
    return <Navigate to="/login" />;
  }

  return element;
};

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
          {/* Teacher Pages */}
          <Route path="/teacher/login" element={<Login/> } />
          <Route path="/teacher/home" element={<PrivateRoute element={<Home />} allowedRoles={['teacher']} />} />
          <Route path="/teacher/my-exams" element={<PrivateRoute element={<MyExams />} allowedRoles={['teacher']} />} />
          <Route path="/teacher/student-exam-data" element={<PrivateRoute element={<StudentExamData />} allowedRoles={['teacher']} />} />
          <Route path="/teacher/class-data" element={<PrivateRoute element={<ClassData />} allowedRoles={['teacher']} />} />
          <Route path="/teacher/generate-exam" element={<PrivateRoute element={<GenerateExam />} allowedRoles={['teacher']} />} />
          <Route path="/teacher/generate-exam-with-ai" element={<PrivateRoute element={<GenerateExamWithAi />} allowedRoles={['teacher']} />} />
          <Route path='/teacher/student' element={<PrivateRoute element={<TStudentData />} allowedRoles={['teacher']} />} />
          <Route path='/teacher/student-exam-info' element={<PrivateRoute element={<TExamData />} allowedRoles={['teacher','principal']} />} />

          {/* Student Pages */}
          <Route path='/student/home' element={<PrivateRoute element={<StudentHome />} allowedRoles={['student']} />} />
          <Route path='/login' element={<StudentLogin/>}/>
          <Route path='/student/exam' element={<PrivateRoute element={<StudentExam />} allowedRoles={['student']} />} />
          <Route path='/student/my-exams' element={<PrivateRoute element={<StudentMyExams />} allowedRoles={['student']} />} />
          <Route path='/student/exam-info' element={<PrivateRoute element={<ExamData />} allowedRoles={['student']} />} />
          
          

          {/* Principal Pages */}
          <Route path='/principal/home' element={<PrivateRoute element={<PrincipalHome />} allowedRoles={['principal']} />} />
          <Route path='/principal/my-exams' element={<PrivateRoute element={<PrincipalMyExams />} allowedRoles={['principal']} />} />
          <Route path='/principal/exam-data' element={<PrivateRoute element={<PrincipalExamData />} allowedRoles={['principal']} />} />
          <Route path='/principal/class-data' element={<PrivateRoute element={<PrincipalCLassData />} allowedRoles={['principal']} />} />
          <Route path='/principal/teachers-data' element={<PrivateRoute element={<PrincipalTeacherData />} allowedRoles={['principal']} />} />
          <Route path='/principal/generate-exam' element={<PrivateRoute element={<PrincipalGenerateExam />} allowedRoles={['principal']} />} />
          <Route path='/principal/generate-exam-with-ai' element={<PrivateRoute element={<PrincipalGenerateExamWithAi />} allowedRoles={['principal']} />} />
          <Route path='/principal/teacher' element={<PrivateRoute element={<TeacherData />} allowedRoles={['principal']} />} />
          <Route path='/principal/student' element={<PrivateRoute element={<StudentData />} allowedRoles={['principal']} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
