import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { Link, useSearchParams } from "react-router-dom";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import Navbar from './Navbar';
import styles from '../../Css/DetailedExam.module.css';

// Import subject icons
import Telugu from '../../Images/telugu.jpg';
import Hindi from '../../Images/hindi.jpg';
import English from '../../Images/english.jpg';
import Maths from '../../Images/maths.jpg';
import PS from '../../Images/ps.jpg';
import Bio from '../../Images/biology.jpg';
import Social from '../../Images/socialstudies.jpg';
import Science from '../../Images/science.jpg';
import ExamCardIcon from '../../Images/exam_card_icon.jpg';

function TExamData() {
    const [examData, setExamData] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const examId = searchParams.get('exam_id');
    const code = searchParams.get('uniqueCode');

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    useEffect(() => {
        const fetchExamData = async () => {
            if (!examId || !code) {
                showToast('Missing exam ID or unique code', true);
                return;
            }

            try {
                setLoading(true);
                const response = await axiosInstance.get(`/exam/get-student-answers?exam_id=${examId}&uniqueCode=${code}`);
                
                if (!response.data) {
                    throw new Error('No data received');
                }
                
                setExamData(response.data);
            } catch (error) {
                console.error('Error fetching exam data:', error);
                showToast(error.response?.data?.message || 'Failed to fetch exam data', true);
                window.location.href = '/student/my-exams';
            } finally {
                setLoading(false);
            }
        };
        fetchExamData();
    }, [examId, code]);

    const subjectIcons = {
        Telugu,
        Hindi,
        English,
        Mathematics: Maths,
        PhysicalScience: PS,
        Biology: Bio,
        Social,
        Science,
    };

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const stats = {
        totalStudents: examData.exam?.attended || 0,
        highestMarks: examData.exam?.results?.[code] || 0,
    };

    const handleImageError = (e) => {
        e.target.src = defaultIcon;
        showToast('Failed to load subject icon', true);
    };

    if (loading) {
        return <div className={styles.miniLoading}>Loading exam data...</div>;
    }

    const renderQuestionBlock = (question, index, studentAnswer, type) => (
        <div key={index} className={styles.miniQuestionBlock}>
            <div className={styles.miniQuestionHeader}>
                <span className={styles.miniQuestionNumber}>Q{index + 1}</span>
            </div>
            <div className={styles.miniQuestionContent}>
                <p className={styles.miniQuestionText}>{question.question}</p>
                <div className={styles.miniAnswerSection}>
                    <div className={styles.miniCorrectAnswer}>
                        <strong>Correct Answer:</strong> 
                        {type === 'mcq' ? question.correct : question.answer}
                    </div>
                    <div className={styles.miniStudentAnswer}>
                        <strong>Your Answer:</strong> 
                        {studentAnswer || 'Not attempted'}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.miniExamContainer}>
            <Navbar/>
            <div className={styles.miniExamWrapper}>
                <div className={styles.miniExamCard}>
                    <div className={styles.miniCardHeader}>
                        <div className={styles.miniExamDate}>
                            Date: {examData.exam?.exam_date || 'N/A'}
                        </div>
                        <div className={`${styles.miniExamStatus} ${styles[examData.exam?.status?.toLowerCase() || '']}`}>
                            {examData.exam?.status || 'N/A'}
                        </div>
                    </div>
                    <div className={styles.miniCardBody}>
                        <div className={styles.miniSubjectSection}>
                            <div className={styles.miniSubjectIcon}>
                                <img
                                    src={subjectIcons[examData.exam?.subject] || ExamCardIcon}
                                    alt={`${examData.exam?.subject || 'Subject'} Icon`}
                                    onError={handleImageError}
                                />
                            </div>
                            <div className={styles.miniSubjectInfo}>
                                <h3>{examData.exam?.subject || 'N/A'}</h3>
                                <p>{examData.exam?.exam_type || 'N/A'}</p>
                            </div>
                            <Link to="/student/my-exams" className={styles.miniBackLink}>
                                <button className={styles.miniBackButton}>Go Back</button>
                            </Link>
                        </div>
                        
                        <div className={styles.miniMarksSection}>
                            <div className={styles.miniMarksObtained}>
                                <span className={styles.miniMarksValue}>{stats.highestMarks}%</span>
                                <span className={styles.miniMarksLabel}>Marks Obtained</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.miniExamQuestions}>
                    <h2 className={styles.miniQuestionsTitle}>Exam Questions and Answers</h2>
                    
                    {examData.exam?.questions?.exam_paper?.mcqs?.length > 0 && (
                        <div className={styles.miniMcqSection}>
                            <h3>Multiple Choice Questions</h3>
                            {examData.exam.questions.exam_paper.mcqs.map((question, index) => 
                                renderQuestionBlock(
                                    question,
                                    index,
                                    examData.student_answers?.mcq_answers?.[index],
                                    'mcq'
                                )
                            )}
                        </div>
                    )}

                    {examData.exam?.questions?.exam_paper?.description?.length > 0 && (
                        <div className={styles.miniDescriptiveSection}>
                            <h3>Descriptive Questions</h3>
                            {examData.exam.questions.exam_paper.description.map((question, index) => 
                                renderQuestionBlock(
                                    question,
                                    index,
                                    examData.student_answers?.descriptive_answers?.[index + (examData.exam.questions.exam_paper.mcqs?.length || 0)],
                                    'descriptive'
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TExamData;