import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar";
import "../../Css/StudentExamData.css";
import Telugu from '../../Images/telugu.jpg';
import Hindi from '../../Images/hindi.jpg';
import English from '../../Images/english.jpg';
import maths from '../../Images/maths.jpg';
import ps from '../../Images/ps.jpg';
import bio from '../../Images/biology.jpg';
import social from '../../Images/socialstudies.jpg';
import Sce from '../../Images/science.jpg';
import axiosInstance from '../../axiosInstance';
import * as XLSX from 'xlsx';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

const ExamData = () => {
    const [searchParams] = useSearchParams();
    const exam_id = searchParams.get('exam_id');
    const [isMounted, setIsMounted] = useState(true);
    const [examData, setExamData] = useState({
        exam_date: "",
        status: "",
        subject: "",
        exam_type: "",
        total_questions: 0,
        scheduled_time: "",
        duration: 0,
        students: []
    });

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {

        return () => {
            setIsMounted(false);
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const showToast = (message, isError = false) => {
        if (isMounted) {
            Toastify({
                text: message,
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: isError
                        ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                        : "linear-gradient(to right, #00b09b, #96c93d)",
                }
            }).showToast();
        }
    };

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                if (!exam_id) {
                    showToast("No exam ID provided", true);
                    return;
                }

                const response = await axiosInstance.get(`/exam/get-exam-details/${exam_id}`);
                if (!response.data) {
                    throw new Error("No data received from server");
                }
                if (isMounted) {
                    setExamData(response.data);
                }
            } catch (error) {
                console.error('Error fetching exam details:', error);
                showToast(error.response?.data?.message || "Failed to fetch exam details", true);
            }
        };

        fetchExamData();
    }, [exam_id, isMounted]);

    const subjectIcons = {
        Telugu,
        Hindi,
        English,
        Mathematics: maths,
        PhysicalScience: ps,
        Biology: bio,
        Social: social,
        Science: Sce,
    };

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const filteredStudents = examData.students.filter(
        (student) =>
            student.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.roll_number?.includes(searchTerm)
    );

    const stats = {
        totalStudents: examData.students?.length || 0,
        attended: examData.students?.filter(s => s.marks !== 'N/A')?.length || 0,
        highestMarks: Math.max(...(examData.students?.filter(s => s.marks !== 'N/A').map(s => s.marks) || [0]))
    };

    const exportToExcel = () => {
        try {
            if (!examData.students?.length) {
                showToast("No student data available to export", true);
                return;
            }

            const worksheetData = [
                ['Exam Details'],
                ['Subject', examData.subject],
                ['Date', examData.exam_date],
                ['Time', examData.scheduled_time],
                ['Duration', `${examData.duration} minutes`],
                ['Status', examData.status],
                ['Type', examData.exam_type],
                ['Total Questions', examData.total_questions],
                [],
                ['Student Results'],
                ['S.No', 'Roll Number', 'Name', 'Phone', 'Grade', 'Parent Name', 'Gender', 'Marks']
            ];

            examData.students.forEach((student, index) => {
                worksheetData.push([
                    index + 1,
                    student.roll_number || 'N/A',
                    student.name || 'N/A',
                    student.phone || 'N/A',
                    student.standard || 'N/A',
                    student.parent_name || 'N/A',
                    student.gender || 'N/A',
                    student.marks || 'Not Attempted'
                ]);
            });

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(worksheetData);

            const colWidths = [
                { wch: 5 },
                { wch: 12 },
                { wch: 20 },
                { wch: 15 },
                { wch: 8 },
                { wch: 20 },
                { wch: 10 },
                { wch: 10 }
            ];
            ws['!cols'] = colWidths;

            XLSX.utils.book_append_sheet(wb, ws, 'Exam Results');
            XLSX.writeFile(wb, `${examData.subject}_Exam_Results.xlsx`);

            showToast("Excel file exported successfully");
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            showToast("Failed to export Excel file", true);
        }
    };

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name')}</span>
                    <p className="text">Welcome back to Exams!</p>
                </div>

                <div className="stu-exxam-card">
                    <div className="card-header">
                        <p>Date: {examData.exam_date}</p>
                        <span className={`status ${examData.status?.toLowerCase()}`}>{examData.status}</span>
                    </div>
                    <div className="card-body">
                        <div className="sub">
                            <div className="subject-info">
                                <div className="subject-icon">
                                    <img
                                        src={subjectIcons[examData.subject] || defaultIcon}
                                        alt={`${examData.subject} Icon`}
                                    />
                                </div>
                                <div>
                                    <h3>{examData.subject}</h3>
                                    <p>{examData.exam_type}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <button className="view-more-btn" onClick={exportToExcel}>Export to Excel</button>
                                <Link to="/principal/my-exams">
                                    <button className="view-more-btn">Go Back</button>
                                </Link>
                            </div>
                        </div>
                        <hr style={{ border: "1px solid #000", margin: "20px 0" }} />
                        <div className="stats">
                            <div className="stat-item">
                                <p>{stats.totalStudents}</p>
                                <span>Total students</span>
                            </div>
                            <div className="stat-item">
                                <p>{stats.attended}</p>
                                <span>Total attended</span>
                                <div className="vertical-line"></div>
                            </div>
                            <div className="stat-item">
                                <p>{stats.highestMarks}</p>
                                <span>Highest marks</span>
                                <div className="vertical-line"></div>
                            </div>
                            <div className="stat-item">
                                <p>{examData.duration} Min(s)</p>
                                <span>Duration</span>
                                <div className="vertical-line"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="students">
                    <div className="stu-stu-inner">
                        <h5>Your Students</h5>
                    </div>
                    <input
                        type="text"
                        placeholder="Search by Name or Roll Number"
                        className="search-box"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="table-block">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Roll.no</th>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th>Grade</th>
                                    <th>Parent Name</th>
                                    <th>Gender</th>
                                    <th>Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map((student, index) => (
                                    <tr key={student.roll_number || index}>
                                        <td>{index + 1}</td>
                                        <td>{student.roll_number || 'N/A'}</td>
                                        <td>{student.name || 'N/A'}</td>
                                        <td>{student.phone || 'N/A'}</td>
                                        <td>{student.standard || 'N/A'}</td>
                                        <td>{student.parent_name || 'N/A'}</td>
                                        <td>{student.gender || 'N/A'}</td>
                                        <td>{student.marks || 'Not Attempted'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {filteredStudents.length === 0 && (
                        <p style={{ textAlign: "center", marginTop: "20px" }}>No students found.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default ExamData;
