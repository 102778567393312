// src/axiosInstance.js

import axios from 'axios';

const token = localStorage.getItem('token')

const axiosInstance = axios.create({
    baseURL: 'https://api.avschool.in/api/v1',
    // timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
});



axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
