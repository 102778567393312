import React, { useState, useEffect } from "react";
import "../../Css/GenerateExam.css";
import Navbar from "./Navbar";
import CallMadeIcon from '@mui/icons-material/CallMade';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function GenerateExam() {
    const [formData, setFormData] = useState({
        subject: "",
        extraTopics: "",
        grade: "",
        timings: "",
        dateOfExam: "",
        numberOfMCQs: "",
        numberOfDescriptive: "",
        duration: ""
    });
    const [subjects, setSubjects] = useState([]);
    const [mcqQuestions, setMcqQuestions] = useState([]);
    const [descriptiveQuestions, setDescriptiveQuestions] = useState([]);
    const [errors, setErrors] = useState({});
    const [showCreateQuestions, setShowCreateQuestions] = useState(false);
    const [info, setInfo] = useState([]);
    const [time, setTime] = useState("00:00");

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const response = await axiosInstance.get('/teacher/info');
                setInfo(response.data.classes);
                setSubjects(response.data.subjects);
            } catch (error) {
                console.error('Error fetching teacher info:', error);
                showToast('Failed to fetch teacher information', 'error');
            }
        };
        fetchInfo();
    }, []);

    const showToast = (message, type = 'success') => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: type === 'success' 
                    ? "linear-gradient(to right, #00b09b, #96c93d)"
                    : "linear-gradient(to right, #ff5f6d, #ffc371)",
            },
        }).showToast();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if ((name === "numberOfMCQs" || name === "numberOfDescriptive" || name === "duration") && value < 0) {
            return;
        }

        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === "numberOfMCQs") {
            setMcqQuestions(
                Array.from({ length: parseInt(value || 0) }, () => ({
                    questionText: "",
                    choices: ["", "", "", ""],
                    correct: "",
                    marks: 1
                }))
            );
        } else if (name === "numberOfDescriptive") {
            setDescriptiveQuestions(
                Array.from({ length: parseInt(value || 0) }, () => ({
                    questionText: "",
                    answer: "",
                    marks: 5
                }))
            );
        }
        setTime(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        const fields = {
            subject: "Subject",
            extraTopics: "Extra topics",
            grade: "Grade",
            timings: "Timings",
            dateOfExam: "Date of exam",
            duration: "Duration",
            numberOfMCQs: "Number of MCQs",
            numberOfDescriptive: "Number of Descriptive questions"
        };

        Object.entries(fields).forEach(([field, label]) => {
            if (!formData[field]) {
                newErrors[field] = `${label} is required.`;
            }
        });

        if (parseInt(formData.duration) <= 0) {
            newErrors.duration = "Duration must be greater than 0.";
        }
        if (parseInt(formData.numberOfMCQs) <= 0) {
            newErrors.numberOfMCQs = "Number of MCQs must be greater than 0.";
        }
        if (parseInt(formData.numberOfDescriptive) <= 0) {
            newErrors.numberOfDescriptive = "Number of Descriptive questions must be greater than 0.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleGenerateExam = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setShowCreateQuestions(true);
            showToast('Exam details validated successfully! You can now create questions.', 'success');
        } else {
            setShowCreateQuestions(false);
            showToast('Please fix the errors in the form.', 'error');
        }
    };

    const handleMcqChange = (index, field, value, choiceIndex = null) => {
        const updatedQuestions = [...mcqQuestions];
        if (field === "questionText") {
            updatedQuestions[index][field] = value;
        } else if (field === "choices" && choiceIndex !== null) {
            updatedQuestions[index].choices[choiceIndex] = value;
        } else if (field === "correct" || field === "marks") {
            updatedQuestions[index][field] = value;
        }
        setMcqQuestions(updatedQuestions);
    };

    const handleDescriptiveChange = (index, field, value) => {
        const updatedQuestions = [...descriptiveQuestions];
        updatedQuestions[index][field] = value;
        setDescriptiveQuestions(updatedQuestions);
    };

    const validateQuestions = () => {
        const mcqErrors = mcqQuestions.some(
            (q, index) => {
                if (!q.questionText.trim()) {
                    showToast(`MCQ ${index + 1}: Question text is required`, 'error');
                    return true;
                }
                if (q.choices.some(choice => !choice.trim())) {
                    showToast(`MCQ ${index + 1}: All choices must be filled`, 'error');
                    return true;
                }
                if (!q.correct.trim()) {
                    showToast(`MCQ ${index + 1}: Correct answer is required`, 'error');
                    return true;
                }
                if (q.marks <= 0) {
                    showToast(`MCQ ${index + 1}: Marks must be greater than 0`, 'error');
                    return true;
                }
                return false;
            }
        );

        const descriptiveErrors = descriptiveQuestions.some(
            (q, index) => {
                if (!q.questionText.trim()) {
                    showToast(`Descriptive Q${index + 1}: Question text is required`, 'error');
                    return true;
                }
                if (!q.answer.trim()) {
                    showToast(`Descriptive Q${index + 1}: Answer is required`, 'error');
                    return true;
                }
                if (q.marks <= 0) {
                    showToast(`Descriptive Q${index + 1}: Marks must be greater than 0`, 'error');
                    return true;
                }
                return false;
            }
        );

        return !mcqErrors && !descriptiveErrors;
    };

    const handleSubmitQuestions = async () => {
        if (!validateQuestions()) {
            return;
        }

        const examData = {
            standard: formData.grade,
            num_mcqs: formData.numberOfMCQs,
            num_desc: formData.numberOfDescriptive,
            scheduled_time: formData.timings,
            scheduled_date: formData.dateOfExam,
            subject: formData.subject,
            duration: formData.duration,
            questions: {
                exam_paper: {
                    mcqs: mcqQuestions.map(q => ({
                        question: q.questionText.trim(),
                        options: q.choices.map(choice => choice.trim()),
                        correct: q.correct.trim(),
                        marks: q.marks
                    })),
                    description: descriptiveQuestions.map(q => ({
                        question: q.questionText.trim(),
                        answer: q.answer.trim(),
                        marks: q.marks
                    }))
                }
            }
        };

        try {
            await axiosInstance.post('/exam/create-exam', examData);
            showToast('Exam created successfully!', 'success');
            // Reset form after successful submission
            setFormData({
                subject: "",
                extraTopics: "",
                grade: "",
                timings: "",
                dateOfExam: "",
                numberOfMCQs: "",
                numberOfDescriptive: "",
                duration: ""
            });
            setShowCreateQuestions(false);
        } catch (error) {
            console.error('Error creating exam:', error);
            showToast('Failed to create exam. Please try again.', 'error');
        }
    };

    const today = new Date().toISOString().split("T")[0];
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name')}</span>
                    <p className="text">Welcome back to Exams!</p>
                </div>
                <hr className="ge-divider" />

                <form className="ge-exam-form">
                    <div className="ge-row">
                        <div className="ge-form-group">
                            <label htmlFor="subject">Subject</label>
                            <select
                                        className="gea-select"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Subject</option>
                                        {subjects.map((grade) => (
                                            <option key={grade} value={grade}>
                                                {grade}
                                            </option>
                                        ))}
                                    </select>
                            {errors.subject && <span className="error">{errors.subject}</span>}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="extraTopics">Extra Topics</label>
                            <input
                                type="text"
                                id="extraTopics"
                                name="extraTopics"
                                value={formData.extraTopics}
                                onChange={handleChange}
                                placeholder="Enter Extra Topics"
                            />
                            {errors.extraTopics && <span className="error">{errors.extraTopics}</span>}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="grade">Grade</label>
                            <select
                                id="grade"
                                name="grade"
                                value={formData.grade}
                                onChange={handleChange}
                            >
                                <option value="">Select Grade</option>
                                {info.map((grade) => (
                                    <option key={grade} value={grade}>
                                        {grade}
                                    </option>
                                ))}
                            </select>
                            {errors.grade && <span className="error">{errors.grade}</span>}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="timings">Timings</label>
                            <input
                                type="time"
                                id="timings"
                                name="timings"
                                value={formData.timings}
                                onChange={handleChange}
                            />
                            {errors.timings && <span className="error">{errors.timings}</span>}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="dateOfExam">Date of Exam</label>
                            <input
                                type="date"
                                id="dateOfExam"
                                name="dateOfExam"
                                value={formData.dateOfExam}
                                onChange={handleChange}
                                min={today}
                            />
                            {errors.dateOfExam && (
                                <span className="error">{errors.dateOfExam}</span>
                            )}
                        </div>
                    </div>
                    <div className="ge-row-2">
                        <div className="ge-form-group">
                            <label htmlFor="numberOfMCQs">No. of MCQs</label>
                            <input
                                type="number"
                                id="numberOfMCQs"
                                name="numberOfMCQs"
                                value={formData.numberOfMCQs}
                                onChange={handleChange}
                                min="0"
                            />
                            {errors.numberOfMCQs && (
                                <span className="error">{errors.numberOfMCQs}</span>
                            )}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="numberOfDescriptive">No. of Descriptive</label>
                            <input
                                type="number"
                                id="numberOfDescriptive"
                                name="numberOfDescriptive"
                                value={formData.numberOfDescriptive}
                                onChange={handleChange}
                                min="0"
                            />
                            {errors.numberOfDescriptive && (
                                <span className="error">{errors.numberOfDescriptive}</span>
                            )}
                        </div>
                        <div className="ge-form-group">
                            <label htmlFor="duration">Duration (minutes)</label>
                            <input
                                type="number"
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                onChange={handleChange}
                                min="0"
                            />
                            {errors.duration && (
                                <span className="error">{errors.duration}</span>
                            )}
                        </div>
                        <div className="ge-form-group">
                            <button
                                className="ge-generate-button"
                                onClick={handleGenerateExam}
                                type="button"
                            >
                                <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} />
                            </button>
                        </div>
                    </div>
                </form>

                {showCreateQuestions && (
                    <div className="create-question">
                        <h2>Create Questions</h2>

                        {/* MCQs Section */}
                        <div className="mcq">
                            <h3>MCQs</h3>
                            {mcqQuestions.map((question, index) => (
                                <div key={index} className="question-block">
                                    <h3>Q{index + 1}</h3>
                                    <textarea
                                        placeholder="Enter MCQ question"
                                        value={question.questionText}
                                        onChange={(e) =>
                                            handleMcqChange(index, "questionText", e.target.value)
                                        }
                                    />
                                    <div className="choices">
                                        {question.choices.map((choice, choiceIndex) => (
                                            <div key={choiceIndex} className="choice">
                                                <label>Choice {choiceIndex + 1}:</label>
                                                <input
                                                    type="text"
                                                    value={choice}
                                                    onChange={(e) =>
                                                        handleMcqChange(
                                                            index,
                                                            "choices",
                                                            e.target.value,
                                                            choiceIndex
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mcq-details">
                                        <div className="correct-answer">
                                            <label>Correct Answer:</label>
                                            <input
                                                type="text"
                                                value={question.correct}
                                                onChange={(e) =>
                                                    handleMcqChange(index, "correct", e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="marks">
                                            <label>Marks:</label>
                                            <input
                                                type="number"
                                                value={question.marks}
                                                onChange={(e) =>
                                                    handleMcqChange(index, "marks", parseInt(e.target.value))
                                                }
                                                min="1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Descriptive Questions Section */}
                        <div className="descriptive">
                            <h3>Descriptive Questions</h3>
                            {descriptiveQuestions.map((question, index) => (
                                <div key={index} className="question-block">
                                    <h3>Q{index + 1}</h3>
                                    <textarea
                                        placeholder="Enter descriptive question"
                                        value={question.questionText}
                                        onChange={(e) =>
                                            handleDescriptiveChange(index, "questionText", e.target.value)
                                        }
                                    />
                                    <textarea
                                        placeholder="Enter model answer"
                                        value={question.answer}
                                        onChange={(e) =>
                                            handleDescriptiveChange(index, "answer", e.target.value)
                                        }
                                    />
                                    <div className="marks">
                                        <label>Marks:</label>
                                        <input
                                            type="number"
                                            value={question.marks}
                                            onChange={(e) =>
                                                handleDescriptiveChange(index, "marks", parseInt(e.target.value))
                                            }
                                            min="1"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Submit Button */}
                        <div className="ge-submit-questions">
                            <button onClick={handleSubmitQuestions} className="ge-submit-button">Submit</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default GenerateExam;
