import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { Link, useSearchParams } from "react-router-dom";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import Navbar from './Navbar';
import styles from '../../Css/DetailedExam.module.css';

// Import subject icons
import Telugu from '../../Images/telugu.jpg';
import Hindi from '../../Images/hindi.jpg';
import English from '../../Images/english.jpg';
import Maths from '../../Images/maths.jpg';
import PS from '../../Images/ps.jpg';
import Bio from '../../Images/biology.jpg';
import Social from '../../Images/socialstudies.jpg';
import Science from '../../Images/science.jpg';

function ExamData() {
    const [examData, setExamData] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const examId = searchParams.get('exam_id');
    const code = localStorage.getItem('roll_number');

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                if (!examId) {
                    throw new Error('Exam ID is required');
                }

                setLoading(true);
                const response = await axiosInstance.get(`/student-exam/get-exam-data/${examId}`);
                
                if (!response.data) {
                    throw new Error('No exam data received');
                }

                setExamData(response.data);
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch exam data';
                
                Toastify({
                    text: errorMessage,
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    close: true,
                    style: {
                        background: "linear-gradient(to right, #ff0000, #ff5555)",
                    },
                }).showToast();

                window.location.href = '/student/my-exams';
            } finally {
                setLoading(false);
            }
        };
        fetchExamData();
    }, [examId]);

    const subjectIcons = {
        Telugu,
        Hindi,
        English,
        Mathematics: Maths,
        PhysicalScience: PS,
        Biology: Bio,
        Social,
        Science,
    };

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const stats = {
        totalStudents: examData.exam?.attended || 0,
        highestMarks: examData.exam?.results?.[code] || 0,
    };

    if (loading) {
        return <div className={styles.miniLoading}>Loading exam data...</div>;
    }

    const renderQuestionBlock = (question, index, studentAnswer, type) => (
        <div key={index} className={styles.miniQuestionBlock}>
            <div className={styles.miniQuestionHeader}>
                <span className={styles.miniQuestionNumber}>Q{index + 1}</span>
            </div>
            <div className={styles.miniQuestionContent}>
                <p className={styles.miniQuestionText}>{question.question}</p>
                <div className={styles.miniAnswerSection}>
                    <div className={styles.miniCorrectAnswer}>
                        <strong>Correct Answer:</strong> 
                        {type === 'mcq' ? question.correct : question.answer}
                    </div>
                    <div className={styles.miniStudentAnswer}>
                        <strong>Your Answer:</strong> 
                        {studentAnswer || 'Not attempted'}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.miniExamContainer}>
            <Navbar/>
            <div className={styles.miniExamWrapper}>
                <div className={styles.miniExamCard}>
                    <div className={styles.miniCardHeader}>
                        <div className={styles.miniExamDate}>
                            Date: {examData.exam?.exam_date || 'N/A'}
                        </div>
                        <div className={`${styles.miniExamStatus} ${styles[examData.exam?.status?.toLowerCase() || '']}`}>
                            {examData.exam?.status || 'N/A'}
                        </div>
                    </div>
                    <div className={styles.miniCardBody}>
                        <div className={styles.miniSubjectSection}>
                            <div className={styles.miniSubjectIcon}>
                                <img
                                    src={subjectIcons[examData.exam?.subject] || defaultIcon}
                                    alt={`${examData.exam?.subject || 'Subject'} Icon`}
                                />
                            </div>
                            <div className={styles.miniSubjectInfo}>
                                <h3>{examData.exam?.subject || 'N/A'}</h3>
                                <p>{examData.exam?.exam_type || 'N/A'}</p>
                            </div>
                            <Link to="/student/my-exams" className={styles.miniBackLink}>
                                <button className={styles.miniBackButton}>Go Back</button>
                            </Link>
                        </div>
                        
                        <div className={styles.miniMarksSection}>
                            <div className={styles.miniMarksObtained}>
                                <span className={styles.miniMarksValue}>{stats.highestMarks}</span>
                                <span className={styles.miniMarksLabel}>Marks Obtained</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.miniExamQuestions}>
                    <h2 className={styles.miniQuestionsTitle}>Exam Questions and Answers</h2>
                    
                    <div className={styles.miniMcqSection}>
                        <h3>Multiple Choice Questions</h3>
                        {examData.exam?.questions?.exam_paper?.mcqs.map((question, index) => 
                            renderQuestionBlock(
                                question,
                                index,
                                examData.student_answers?.mcq_answers?.[index],
                                'mcq'
                            )
                        )}
                    </div>

                    <div className={styles.miniDescriptiveSection}>
                        <h3>Descriptive Questions</h3>
                        {examData.exam?.questions?.exam_paper?.description.map((question, index) => 
                            renderQuestionBlock(
                                question,
                                index,
                                examData.student_answers?.descriptive_answers?.[index + examData.exam?.questions?.exam_paper?.mcqs.length],
                                'descriptive'
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExamData;