import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar";
import "../../Css/StudentExamData.css";
import Telugu from '../../Images/telugu.jpg';
import Hindi from '../../Images/hindi.jpg';
import English from '../../Images/english.jpg';
import maths from '../../Images/maths.jpg';
import ps from '../../Images/ps.jpg';
import bio from '../../Images/biology.jpg';
import social from '../../Images/socialstudies.jpg';
import Sce from '../../Images/science.jpg';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

const StudentExamData = () => {
    const [searchParams] = useSearchParams();
    const exam_id = searchParams.get('exam_id');
    const [examData, setExamData] = useState({
        exam_date: "",
        status: "",
        subject: "",
        exam_type: "",
        total_questions: 0,
        scheduled_time: "",
        duration: 0,
        students: []
    });

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;
        const fetchExamData = async () => {
            if (!exam_id) {
                if (mounted) {
                    setError("Exam ID is required");
                    setLoading(false);
                    Toastify({
                        text: "Exam ID is required",
                        duration: 3000,
                        gravity: "top",
                        position: "right",
                        close: true,
                        style: {
                            background: "linear-gradient(to right, #ff6b6b, #ff8787)",
                        },
                    }).showToast();
                }
                return;
            }

            try {
                const response = await axiosInstance.get(`/exam/get-exam-details/${exam_id}`);
                if (!mounted) return;
                console.log(response.data)

                setExamData(response.data);
                setLoading(false);

                if (!response.data) {
                    throw new Error('No data received from server');
                }

                Toastify({
                    text: "Exam data loaded successfully",
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    close: true,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                }).showToast();

                setError(null);

            } catch (error) {
                console.error('Error fetching exam details:', error);
                if (mounted) {
                    setError(error.message);
                    setLoading(false);
                    Toastify({
                        text: `Error loading exam data: ${error.message}`,
                        duration: 3000,
                        gravity: "top",
                        position: "right",
                        close: true,
                        style: {
                            background: "linear-gradient(to right, #ff6b6b, #ff8787)",
                        },
                    }).showToast();
                }
            }
        };

        fetchExamData();
        return () => {
            mounted = false;
        };
    }, [exam_id]);

    const subjectIcons = {
        Telugu,
        Hindi,
        English,
        Mathematics: maths,
        PhysicalScience: ps,
        Biology: bio,
        Social: social,
        Science: Sce,
    };

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const filteredStudents = examData.students.filter(
        (student) =>
            student.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.rollNo?.includes(searchTerm)
    );

    const stats = {
        totalStudents: examData.students?.length || 0,
        attended: examData.students?.filter(s => s.marks !== 'N/A')?.length || 0,
        highestMarks: Math.max(...(examData.students?.filter(s => s.marks !== 'N/A').map(s => {
            // Extract the numerator from marks format like "2/12"
            const match = s.marks.toString().match(/^(\d+)\/\d+$/);
            return match ? parseInt(match[1], 10) : 0;
        }) || [0]))
    };

    if (loading) {
        return (
            <>
                <Navbar />
                <div className="teacher-home">
                    <div className="loading">Loading exam data...</div>
                </div>
            </>
        );
    }

    if (error) {
        return (
            <>
                <Navbar />
                <div className="teacher-home">
                    <div className="error">Error: {error}</div>
                    <Link to="/teacher/my-exams">
                        <button className="view-more-btn">Go Back</button>
                    </Link>
                </div>
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name') || 'Teacher'}</span>
                    <p className="text">Welcome back to Exams!</p>
                </div>

                <div className="stu-exxam-card">
                    <div className="card-header" style={{ fontSize: "1.3em" }}>
                        <p>Date: {examData.exam_date || 'N/A'}</p>
                        <span className={`status ${examData.status?.toLowerCase()}`}>
                            {examData.status || 'N/A'}
                        </span>
                    </div>
                    <div className="card-body">
                        <div className="sub">
                            <div className="subject-info">
                                <div className="subject-icon">
                                    <img
                                        src={subjectIcons[examData.subject] || defaultIcon}
                                        alt={`${examData.subject || 'Subject'} Icon`}
                                        onError={(e) => {
                                            e.target.src = defaultIcon;
                                        }}
                                    />
                                </div>
                                <div>
                                    <h3 style={{
                                        fontWeight: "normal",
                                        marginBottom: "5px"
                                    }}>{examData.subject || 'N/A'}</h3>
                                    <p>{examData.exam_type || 'N/A'}</p>
                                </div>
                            </div>
                            <Link to="/teacher/my-exams">
                                <button className="view-more-btn">Go Back</button>
                            </Link>
                        </div>
                        <hr style={{ border: "1px solid #000", margin: "20px 0" }} />
                        <div className="stats">
                            <div className="stat-item">
                                <p>{stats.totalStudents}</p>
                                <span>Total students</span>
                            </div>
                            <div className="stat-item">
                                <p>{stats.attended}</p>
                                <span>Total attended</span>
                                <div className="vertical-line"></div>
                            </div>
                            <div className="stat-item">
                                <p>{stats.highestMarks === -Infinity ? 'N/A' : stats.highestMarks}</p>
                                <span>Highest Marks</span>
                                <div className="vertical-line"></div>
                            </div>
                            <div className="stat-item">
                                <p>{examData.duration || 0} Min(s)</p>
                                <span>Duration</span>
                                <div className="vertical-line"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="students">
                    <div className="stu-stu-inner">
                        <h5>Your Students</h5>
                    </div>
                    <input
                        type="text"
                        placeholder="Search by Name or Roll Number"
                        className="search-box"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {filteredStudents.length > 0 ? (
                        <div className="table-block">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Roll.no</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Grade</th>
                                        <th>Parent Name</th>
                                        <th>Gender</th>
                                        <th>Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map((student, index) => (
                                        <tr key={student.uniqueCode || index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/teacher/student?uniqueCode=${student.uniqueCode}`}
                                                    className="link-row"
                                                    style={{
                                                        color: '#3366ff',
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {student.roll_number || 'N/A'}
                                                </Link>
                                            </td>
                                            <td>{student.name || 'N/A'}</td>
                                            <td>{student.phone || 'N/A'}</td>
                                            <td>{student.standard || 'N/A'}</td>
                                            <td>{student.parent_name || 'N/A'}</td>
                                            <td>{student.gender || 'N/A'}</td>
                                            <td>{student.marks ?? 'Not Attempted'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                            {searchTerm ? "No students found matching your search." : "No students available."}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default StudentExamData;
