import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import '../../Css/Navbar.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Navbar() {

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError
                    ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            }
        }).showToast();
    };

    const handleModal = () => setIsModalOpen(!isModalOpen);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) return "Password must be at least 8 characters long";
        if (!hasUpperCase || !hasLowerCase) return "Password must contain both uppercase and lowercase letters";
        if (!hasNumbers) return "Password must contain at least one number";
        if (!hasSpecialChar) return "Password must contain at least one special character";
        return null;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const validationError = validatePassword(password);
            if (validationError) {
                showToast(validationError, true);
                return;
            }
            if (password !== confirmPassword) {
                showToast('Passwords do not match', true);
                return;
            }
            await axiosInstance.post('/principal/reset-password', { password });
            showToast('Password reset successfully');
            handleModal();
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Error resetting password:', error);
            showToast(error.response?.data?.message || 'Failed to reset password. Please try again.', true);
        }
    };

    const handleLogout = () => {
        try {
            localStorage.clear();
            window.location.href = '/login';
            showToast('Logged out successfully');
            setIsMenuOpen(false); // Close menu on logout
        } catch (error) {
            console.error('Error during logout:', error);
            showToast('Error logging out', true);
        }
    };

    return (
        <>
            <header className="navbar neu">
                <nav className="navigation">
                    <Link to="/principal/home" style={{ textDecoration: 'none' }}>
                        <h1 className="app-title">
                            <span style={{ fontSize: '60px' }}>E</span>
                            <span style={{ fontSize: '50px' }}>xams</span>
                        </h1>
                    </Link>
                    <button className="hamburger" onClick={toggleMenu}>
                        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                    </button>
                    <ol className={`menu ${isMenuOpen ? 'open' : ''}`}>
                        <li className={`menu-items ${location.pathname === '/principal/home' ? 'active' : ''}`}>
                            <Link to="/principal/home" onClick={() => setIsMenuOpen(false)}>Home</Link>
                        </li>
                        <li className={`menu-items ${location.pathname === '/principal/my-exams' ? 'active' : ''}`}>
                            <Link to="/principal/my-exams" onClick={() => setIsMenuOpen(false)}>Exams</Link>
                        </li>
                        <li className={`menu-items ${location.pathname === '/principal/teachers-data' ? 'active' : ''}`}>
                            <Link to="/principal/teachers-data" onClick={() => setIsMenuOpen(false)}>Teachers</Link>
                        </li>
                        <li className="menu-items">
                            <Link onClick={() => { handleModal(); setIsMenuOpen(false); }}>Reset Password</Link>
                        </li>
                        <li className="menu-items logout-item">
                            <button className="logout-button" onClick={handleLogout}>
                                <LogoutIcon style={{ fontSize: '15px' }} /> Log out
                            </button>
                        </li>
                    </ol>
                </nav>
            </header>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Reset Password</h3>
                        <form onSubmit={handleResetPassword}>
                            <div className="password-container-1">
                                <input
                                    className="input-1"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"                                    
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    minLength={8}
                                />
                                <span
                                    className="toggle-password"
                                    onClick={()=> setShowPassword(!showPassword)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </span>
                            </div>
                            <div className="password-container-1">
                                <input
                                    className="input-1"
                                    type={showPassword ? 'text' : 'password'}
                                    name="confirmpassword"
                                    placeholder="Confirm Password"                                    
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    minLength={8}
                                />
                                <span
                                    className="toggle-password"
                                    onClick={()=> setShowPassword(!showPassword)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </span>
                            </div>
                            <div className="modal-actions">
                                <button type="button" onClick={handleModal} className="cancel-btn">Cancel</button>
                                <button type="submit" className="save-btn">Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Navbar;