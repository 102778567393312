import React, { useState, useRef, useEffect } from 'react';
import Timer from './Timer';
import Navbar from './Navbar';
import '../../Css/Exam.css';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Exam() {
    const [searchParams] = useSearchParams();
    const exam_id = searchParams.get('id');
    const questionRefs = useRef([]);

    const [examData, setExamData] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [descriptiveAnswers, setDescriptiveAnswers] = useState({});
    const [visitedQuestions, setVisitedQuestions] = useState(new Set());
    const [isTimerBlinking, setIsTimerBlinking] = useState(false);
    const [loading, setLoading] = useState(true);
    const [examStarted, setExamStarted] = useState(false);
    const [showInstructions, setShowInstructions] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showStartConfirm, setShowStartConfirm] = useState(false);
    const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                const response = await axiosInstance.get(`/student-exam/questions/${exam_id}`);
                if (!response.data) {
                    throw new Error('No exam data received');
                }
                setExamData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching exam data:', error);
                Toastify({
                    text: "Failed to load exam data. Please refresh the page.",
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    close: true,
                    style: {
                        background: "linear-gradient(to right, #ff416c, #ff4b2b)",
                    },
                }).showToast();
                setLoading(false);
            }
        };

        if (!exam_id) {
            Toastify({
                text: "Invalid exam ID",
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: "linear-gradient(to right, #ff416c, #ff4b2b)",
                },
            }).showToast();
            return;
        }

        fetchExamData();
    }, [exam_id]);

    const startExam = () => {
        setShowStartConfirm(true);
    };

    const confirmStartExam = () => {
        setShowStartConfirm(false);
        setShowInstructions(false);
        setExamStarted(true);
        
        Toastify({
            text: "Exam started! Good luck!",
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    if (loading || !examData) {
        return <div>Loading...</div>;
    }

    if (!examStarted) {
        return (
            
            <>
                <Navbar />
                {showInstructions && (
                    <div className="instructions-overlay">
                        <div className="instructions-modal">
                            <h2>Ready to begin your exam?</h2>
                            <div className="instructions-content">
                                <h3>Important Instructions</h3>
                                <ul>
                                    <li><span className="icon">📡</span> Make sure you have stable internet connection</li>
                                </ul>
                                <button onClick={startExam} className="start-exam-btn">
                                    Start Exam
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {showStartConfirm && (
                    <div className="confirmation-overlay">
                        <div className="confirmation-modal">
                            <h3>Start Exam Confirmation</h3>
                            <p>Are you sure you want to start the exam?</p>
                            <div className="confirmation-buttons">
                                <button onClick={confirmStartExam} className="confirm-btn">Yes, Start</button>
                                <button onClick={() => setShowStartConfirm(false)} className="cancel-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
                <style jsx>{`
                    .instructions-overlay, .confirmation-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.7);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1000;
                    }
                    
                    .instructions-modal, .confirmation-modal {
                        background: white;
                        padding: 2rem;
                        border-radius: 15px;
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                        max-width: 500px;
                        width: 90%;
                    }
                    
                    .confirmation-modal {
                        text-align: center;
                    }
                    
                    .confirmation-buttons {
                        display: flex;
                        justify-content: center;
                        gap: 1rem;
                        margin-top: 1.5rem;
                    }
                    
                    .confirm-btn, .cancel-btn {
                        padding: 0.5rem 1.5rem;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        font-size: 1rem;
                    }
                    
                    .confirm-btn {
                        background: #1e4091;
                        color: white;
                    }
                    
                    .cancel-btn {
                        background: #dc3545;
                        color: white;
                    }
                    
                    .instructions-modal h2 {
                        color: #1e4091;
                        text-align: center;
                        margin-bottom: 1.5rem;
                        font-size: 1.8rem;
                    }
                    
                    .instructions-content {
                        padding: 1rem;
                    }
                    
                    .instructions-content h3 {
                        color: #333;
                        margin-bottom: 1rem;
                    }
                    
                    .instructions-content ul {
                        list-style: none;
                        padding: 0;
                    }
                    
                    .instructions-content li {
                        margin: 1rem 0;
                        padding: 0.8rem;
                        background: #f8f9fa;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        font-size: 1.1rem;
                    }
                    
                    .icon {
                        margin-right: 10px;
                        font-size: 1.2rem;
                    }
                    
                    .start-exam-btn {
                        display: block;
                        width: 100%;
                        padding: 1rem;
                        margin-top: 2rem;
                        background: #1e4091;
                        color: white;
                        border: none;
                        border-radius: 8px;
                        font-size: 1.1rem;
                        cursor: pointer;
                        transition: background 0.3s ease;
                    }
                    
                    .start-exam-btn:hover {
                        background: #152c5b;
                    }
                `}</style>
            </>
        );
    }

    const questionsList = [
        ...examData.questions.exam_paper.mcqs.map(mcq => ({ ...mcq, type: "mcq" })),
        ...examData.questions.exam_paper.description.map(desc => ({ ...desc, type: "text" }))
    ];

    questionRefs.current = questionsList.map(
        (question, i) => questionRefs.current[i] ?? React.createRef()
    );

    const handleOptionChange = (questionIndex, option) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [questionIndex]: option
        }));
    };

    const handleDescriptiveChange = (questionIndex, answer) => {
        setDescriptiveAnswers((prev) => ({
            ...prev,
            [questionIndex]: answer
        }));
    };

    const handleQuestionVisit = (questionIndex) => {
        setVisitedQuestions((prev) => new Set(prev).add(questionIndex));
        if (questionRefs.current[questionIndex]) {
            questionRefs.current[questionIndex].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const handleSubmit = async () => {
        // Check if already submitting
        if (isSubmitting) {
            Toastify({
                text: "Submission already in progress...",
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: "linear-gradient(to right, #ff416c, #ff4b2b)",
                },
            }).showToast();
            return;
        }

        // Show confirmation dialog if not already showing
        if (!showSubmitConfirm) {
            setShowSubmitConfirm(true);
            return;
        }

        // Validate all questions are answered
        const unansweredQuestions = [];
        questionsList.forEach((question, index) => {
            if (question.type === "mcq" && !selectedOptions[index]) {
                unansweredQuestions.push(index + 1);
            }
            if (question.type === "text" && (!descriptiveAnswers[index] || !descriptiveAnswers[index].trim())) {
                unansweredQuestions.push(index + 1);
            }
        });

        if (unansweredQuestions.length > 0) {
            alert(`Questions are unanswered. Please review before submitting.`);
            setShowSubmitConfirm(false);
            return;
        }

        // Start submission
        setIsSubmitting(true);
        setShowSubmitConfirm(false);

        try {
            // Prepare submission data
            const submissionData = {
                exam_id: exam_id,
                mcq_answers: {},
                descriptive_answers: {}
            };

            // Process MCQ answers
            questionsList.forEach((question, index) => {
                if (question.type === "mcq" && selectedOptions[index]) {
                    submissionData.mcq_answers[index] = selectedOptions[index];
                }
            });

            // Process descriptive answers
            questionsList.forEach((question, index) => {
                if (question.type === "text" && descriptiveAnswers[index]) {
                    submissionData.descriptive_answers[index] = descriptiveAnswers[index].trim();
                }
            });

            // Submit exam
            const response = await axiosInstance.post('/student-exam/submit', submissionData);
            
            if (response.status === 200) {
                Toastify({
                    text: "Exam submitted successfully!",
                    duration: 3000,
                    gravity: "top",
                    position: "right",
                    close: true,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                }).showToast();
                
                // Redirect after successful submission
                setTimeout(() => {
                    window.location.href = '/student/home';
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting exam:', error);
            Toastify({
                text: error.response?.data?.message || "Error submitting exam. Please try again.",
                duration: 5000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: "linear-gradient(to right, #ff416c, #ff4b2b)",
                },
            }).showToast();
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleTimerBlink = () => {
        setIsTimerBlinking(true);
    };

    return (
        <>
        <div className='interview-main '>
            <div className='inv-container'>
                <div className='questions'>
                    {questionsList.map((questionData, index) => (
                        <div
                            key={index}
                            className='question-block'
                            ref={questionRefs.current[index]}
                            onClick={() => handleQuestionVisit(index)}
                        >
                            <h3>{index + 1}. {questionData.question}</h3>
                            {questionData.type === "mcq" && (
                                <div className="options">
                                    {questionData.options.map((option, optIndex) => (
                                        <label key={optIndex}>
                                            <input
                                                type="radio"
                                                name={`question-${index}`}
                                                checked={selectedOptions[index] === option}
                                                onChange={() => handleOptionChange(index, option)}
                                            /> {option}
                                        </label>
                                    ))}
                                </div>
                            )}
                            {questionData.type === "text" && (
                                <textarea
                                    rows="4"
                                    className={`descriptive-answer ${descriptiveAnswers[index] ? 'answered' : ''}`}
                                    placeholder="Write your answer here..."
                                    value={descriptiveAnswers[index] || ""}
                                    onChange={(e) => handleDescriptiveChange(index, e.target.value)}
                                ></textarea>
                            )}
                        </div>
                    ))}
                </div>
                <div className='time-numbers'>
                    <div className='timer'>
                        <Timer 
                            duration={Number(examData.duration)} 
                            onBlink={handleTimerBlink} 
                            onTimerEnd={() => {}}
                        />
                    </div>
                    <div className='inv-number'>
                        {questionsList.map((_, index) => {
                            const isAnswered =
                                (questionsList[index].type === "mcq" && selectedOptions[index]) ||
                                (questionsList[index].type === "text" && descriptiveAnswers[index]);
                            const isVisited = visitedQuestions.has(index);

                            return (
                                <div
                                    key={index}
                                    className={`question-number ${
                                        isAnswered
                                            ? 'answered'
                                            : isVisited
                                            ? 'visited'
                                            : 'not-visited'
                                    }`}
                                    onClick={() => handleQuestionVisit(index)}
                                >
                                    {index + 1}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='inv-sub'>
                <button
                    className='inv-submit-btn'
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    Submit
                </button>
            </div>
            
        </div>
        {showSubmitConfirm && (
            <div className="confirmation-overlay" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
            }}>
                <div className="confirmation-modal" style={{
                    background: 'white',
                    padding: '2rem',
                    borderRadius: '15px',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
                    maxWidth: '500px',
                    width: '90%',
                    textAlign: 'center'
                }}>
                    <h3>Submit Exam Confirmation</h3>
                    <p>Are you sure you want to submit the exam?</p>
                    <div className="confirmation-buttons" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '1rem',
                        marginTop: '1.5rem'
                    }}>
                        <button onClick={handleSubmit} className="confirm-btn" style={{
                            padding: '0.5rem 1.5rem',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            background: '#1e4091',
                            color: 'white'
                        }}>Yes, Submit</button>
                        <button onClick={() => setShowSubmitConfirm(false)} className="cancel-btn" style={{
                            padding: '0.5rem 1.5rem',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            background: '#dc3545',
                            color: 'white'
                        }}>Cancel</button>
                    </div>
                </div>
            </div>
        )}
        </>
    );
}

export default Exam;
