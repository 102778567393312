import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../Css/TeacherLogin.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        roll_number: ''
    });

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const validateForm = () => {
        if (!formData.roll_number || !formData.roll_number.trim()) {
            showToast('Student ID is required', true);
            return false;
        }

        // Add additional validation rules if needed
        if (formData.roll_number.length < 3) {
            showToast('Student ID must be at least 3 characters long', true);
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axiosInstance.post('/login?role=student', {
                uniqueCode: formData.roll_number.trim()
            });

            if (response?.status === 200 && response?.data) {
                const { access_token, role } = response.data;
                
                localStorage.setItem('token', access_token);
                localStorage.setItem('role', role);
                localStorage.setItem('roll_number', formData.roll_number.trim());
                // localStorage.setItem('uniqueCode', 
                showToast('Login successful! Redirecting...');
                
                // Use timeout to allow toast to be seen
                setTimeout(() => {
                    window.location.href = '/student/home';
                }, 1000);
            }
        } catch (error) {
            console.error('Login error:', error);
            
            const errorMessage = 'Invalid Student ID. Please try again.';
            
            showToast(errorMessage, true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="container">
            <div className="heading">Student Log In</div>
            <form className="form" onSubmit={handleSubmit}>
                <div>
                    <input
                        placeholder="Enter Student ID"
                        id="roll_number"
                        name="roll_number"
                        type="text"
                        className="input"
                        value={formData.roll_number}
                        onChange={handleChange}
                        autoComplete="off"
                        aria-label="Student ID"
                    />
                </div>
                <input 
                    value="Log In" 
                    type="submit" 
                    className="login-button"
                    aria-label="Login button" 
                />
            </form>
            <div className="register-link">
                <p>
                    If you're a Teacher? <Link to="/teacher/login">Login Here</Link>
                </p>
            </div>
        </div>
    );
}

export default Login;
