import React, { useState, useEffect, useRef } from 'react';
import ExamCard from './ExamCard';
import Navbar from './Navbar';
import '../../Css/MyExams.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function MyExams() {
    const [selectedClass, setSelectedClass] = useState('Class 5');
    const [searchQuery, setSearchQuery] = useState('');
    const [examsData, setExamsData] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(true);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const showToast = (message, isError = false) => {
        // if (isMounted.current) {
            Toastify({
                text: message,
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: isError 
                        ? "linear-gradient(to right, #ff6b6b, #ff8787)"
                        : "linear-gradient(to right, #00b09b, #96c93d)",
                },
            }).showToast();
        // }
    };

    // Fetch exams data
    useEffect(() => {
        const fetchExams = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/exam/all-exams');
                
                if (!response?.data) {
                    throw new Error('No data received from server');
                }
                setLoading(false);
                setExamsData(response.data);
                showToast('Exams loaded successfully');
                
                if (isMounted.current) {
                    
                }
            } catch (error) {
                console.error('Error fetching exams:', error);
                if (isMounted.current) {
                    showToast(
                        `Failed to load exams: ${error.response?.data?.message || error.message}`,
                        true
                    );
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };
        fetchExams();
    }, []);

    // Sort exams by status with memoization
    const sortedExamData = React.useMemo(() => {
        const examData = examsData[selectedClass] || [];
        return examData.sort((a, b) => {
            const statusOrder = { "Running": 1, "Upcoming": 2, "Completed": 3 };
            return statusOrder[a.status] - statusOrder[b.status];
        });
    }, [examsData, selectedClass]);

    // Handle class selection
    const handleClassSelection = (cls) => {
        if (cls !== selectedClass) {
            setSelectedClass(cls);
            showToast(`Switched to ${cls}`);
        }
    };

    return (
        <>
            <Navbar />
            <div className="myexam-block teacher-home">
                <div className="welcome-section">
                    <div>
                        <span className="welcome-name">
                            Hello, {localStorage.getItem('name') || 'User'}
                        </span>
                        <p className="text">Welcome back to Exams!</p>
                    </div>
                </div>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '2em' }}>Exams</h3>
                    <div className="class-buttons">
                        {Object.keys(examsData).map(cls => (
                            <button
                                key={cls}
                                className={`cls-btn ${selectedClass === cls ? 'active' : ''}`}
                                onClick={() => handleClassSelection(cls)}
                            >
                                {cls}
                            </button>
                        ))}
                    </div>
                </section>
                <div className="exam-list">
                    {loading ? (
                        <div>Loading exams...</div>
                    ) : sortedExamData.length === 0 ? (
                        <div>Please select a class to view exams</div>
                    ) : (
                        sortedExamData.map((exam) => (
                            <ExamCard 
                                key={exam.exam_id}
                                date={exam.exam_date}
                                status={exam.status}
                                subject={exam.subject}
                                examType={exam.exam_type}
                                stats={{
                                    totalQuestions: exam.total_questions,
                                    scheduledTime: exam.scheduled_time,
                                    duration: exam.duration,
                                    scheduledDate: exam.exam_date,
                                    exam_id: exam.exam_id
                                }}
                                room="Not specified"
                            />
                        ))
                    )}
                </div>
            </div>
        </>
    );
}

export default MyExams;
