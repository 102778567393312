import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';
import axiosInstance from '../../axiosInstance';

// Import subject images
import Telugu from '../../Images/telugu.jpg';
import Hindi from '../../Images/hindi.jpg';
import English from '../../Images/english.jpg';
import maths from '../../Images/maths.jpg';
import ps from '../../Images/ps.jpg';
import bio from '../../Images/biology.jpg';
import social from '../../Images/socialstudies.jpg';
import Sce from '../../Images/science.jpg';

const ExamCard = ({ date, status, subject, examType, stats, onEdit, onDelete, onComplete }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [edate, setEDate] = useState('');
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState('');

    // Map subjects to specific icons
    const subjectIcons = {
        Telugu,
        Hindi, 
        English,
        Mathematics: maths,
        PhysicalScience: ps,
        Biology: bio,
        Social: social,
        Science: Sce
    };

    const defaultIcon = "https://via.placeholder.com/40?text=Subject";

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            }
        }).showToast();
    };

    const deleteExam = async (exam_id) => {
        try {
            await axiosInstance.delete(`/exam/delete-exam/${exam_id}`);
            showToast('Exam deleted successfully');
            window.location.replace('/principal/my-exams');
        } catch (error) {
            console.error(`Failed to delete exam with ID ${exam_id}:`, error);
            showToast('Failed to delete exam', true);
        }
    };

    const completeExam = async (exam_id) => {
        try {
            await axiosInstance.get(`/exam/complete-exam/${exam_id}`);
            showToast('Exam marked as complete');
            window.location.replace('/principal/my-exams');
        } catch (error) {
            console.error(`Failed to complete exam with ID ${exam_id}:`, error);
            showToast('Failed to complete exam', true);
        }
    };

    

    const updateExam = async (e) => {
        e.preventDefault();
        
        // if (!validateExamData()) {
        //     return;
        // }

        try {
            await axiosInstance.patch(`/exam/update-exam/${stats.exam_id}`, {
                scheduled_date: edate,
                scheduled_time: time,
                duration: duration
            });
            showToast('Exam updated successfully');
            setIsModalOpen(false);
            window.location.replace('/principal/my-exams');
        } catch (err) {
            console.error('Error updating exam:', err);
            showToast(err.response?.data?.detail || 'Failed to update exam', true);
        }
    };

    return (
        <>
        <div className="exxam-card">
            <div className="card-header">
                <p>Date: {date}</p>
                <span className={`status ${status?.toLowerCase()}`}>{status}</span>
            </div>
            <div className="card-body">
                <div className='sub'>
                    <div className="subject-info">
                        <div className="subject-icon">
                            <img
                                src={subjectIcons[subject] || defaultIcon}
                                alt={`${subject} Icon`}
                            />
                        </div>
                        <div>
                            <h3>{subject}</h3>
                            <p>{examType}</p>
                        </div>
                    </div>
                    <button className="view-more-btn" onClick={()=>setIsModalOpen(true)}>
                        <EditIcon fontSize='small'/> Edit
                    </button>
                    <button 
                        className="view-more-btn" 
                        style={{background:"red", color:"white"}}
                        onClick={()=>deleteExam(stats?.exam_id)}
                    >
                        <DeleteIcon fontSize='small'/> Delete
                    </button>
                    <button className='view-more-btn' onClick={()=>completeExam(stats?.exam_id)}>
                        Complete
                    </button>
                    <Link to={`/principal/exam-data?exam_id=${stats?.exam_id}`} style={{textDecoration:"none"}}>
                        <button className="view-more-btn">View more</button>
                    </Link>
                </div>
                <hr style={{ border: "1px solid #000", margin: "20px 0" }} />
                <div className="stats">
                    <div className="stat-item">
                        <p>{stats?.totalQuestions || 0}</p>
                        <span>Total Questions</span>
                    </div>
                    <div className="stat-item">
                        <p>{stats?.scheduledTime}</p>
                        <span>Scheduled Time</span>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="stat-item">
                        <p style={{ fontSize: '1.2em', paddingRight:'2px' }}>
                            {stats?.scheduledDate}
                        </p>
                        <span>Scheduled Date</span>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="stat-item">
                        <p>{stats?.duration || 0} Min(s)</p>
                        <span>Duration</span>
                        <div className="vertical-line"></div>
                    </div>
                </div>
            </div>
        </div>
        {isModalOpen && (
            <div className="modal">
                <div className="modal-content">
                    <h3>Edit Exam</h3>
                    <form onSubmit={updateExam}>
                        <input
                            type="date"
                            name="date"
                            placeholder="Select Date"
                            value={edate}
                            onChange={(e) => setEDate(e.target.value)}
                            required
                        />
                        <input
                            type="time"
                            name="time"
                            placeholder="Select Time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            required
                        />
                        <input
                            type="number"
                            name="duration"
                            placeholder="Enter the Duration of the Exam (minutes)"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            min="1"
                            required
                        />
                        <div className="modal-actions">
                            <button
                                type="button"
                                // onClick={resetForm}
                                className="cancel-btn"
                            >
                                Cancel
                            </button>
                            <button type="submit" className="save-btn">
                                Update Exam
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )}
        </>
    );
};

export default ExamCard;