import React from 'react';
import exam_card_icon from '../../Images/exam_card_icon.jpg';
import '../../Css/StudentExamCard.css';
import { Link } from 'react-router-dom';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

const StudentExamCard = ({ 
    exam_date, 
    status, 
    subject, 
    exam_type, 
    examStats ,
    exam_icon
}) => {
    const defaultIcon = 'https://via.placeholder.com/40?text=Subject';

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right", 
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const handleImageError = () => {
        showToast('Failed to load subject icon', true);
    };

    if (!examStats || !status || !subject) {
        showToast('Missing required exam data', true);
        return null;
    }

    return (
        <div className="student-exam-card">
            <div className="student-card-header">
                <p>Date: {exam_date || 'Not specified'}</p>
                <span className={`student-status ${status.toLowerCase()}`}>
                    {status}
                </span>
            </div>
            <div className="student-card-body">
                <div className="student-sub">
                    <div className="student-subject-info">
                        <div className="student-subject-icon">
                            <img
                                src={exam_icon}
                                alt={`${subject} Icon`}
                                onError={handleImageError}
                            />
                        </div>
                        <div>
                            <h3>{subject}</h3>
                            <p>{exam_type || 'Not specified'}</p>
                        </div>
                    </div>
                    <div className="student-stu-marks">
                        <p style={{ height: '100%' }}>
                            {status === 'Completed' ? (
                                <div>
                                    <Link style={{
                                        textDecoration:"none"
                                    }}
                                        to={`/student/exam-info?exam_id=${examStats.exam_id}`}
                                        onClick={() => showToast('Loading exam results...')}
                                    >
                                        <button className="view-more-btn">
                                            View Score
                                        </button>
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <span style={{ fontSize: '1em', color: '#666' }}>
                                        Not announced
                                    </span>
                                </div>
                            )}
                            <span style={{ fontSize: '.7em' }}>Results</span>
                        </p>
                    </div>
                </div>
                <hr style={{ border: '1px solid #000', margin: '20px 0' }} />
                <div className="student-stats">
                    <div className="student-stat-item">
                        <p>{examStats.totalQuestions || 0}</p>
                        <span>Total Questions</span>
                    </div>
                    <div className="student-stat-item">
                        <p>{examStats.scheduledTime || 'TBD'}</p>
                        <span>Time</span>
                        <div className="student-vertical-line"></div>
                    </div>
                    <div className="student-stat-item">
                        <p style={{ fontSize: '1.2em', paddingRight: '2px' }}>
                            {examStats.scheduledDate || 'TBD'}
                        </p>
                        <span>Date</span>
                        <div className="student-vertical-line"></div>
                    </div>
                    <div className="student-stat-item">
                        <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', gap: '3px' }}>
                            {examStats.duration || 0}
                        </p>
                        <span>Duration Min(s)</span>
                        <div className="student-vertical-line"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentExamCard;
