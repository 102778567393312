import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import '../../Css/TeacherLogin.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({ username: '', password: '' });

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { username: '', password: '' };

        if (!username.trim()) {
            newErrors.username = 'Username is required.';
            isValid = false;
        } else if (username.trim().length < 4) {
            newErrors.username = 'Username must be at least 4 characters.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast('Please fix the validation errors', true);
            return;
        }

        try {
            const response = await axiosInstance.post('/login?role=teacher', { 
                username: username.trim(), 
                password 
            });
            
            const { access_token, role } = response.data;
            
            if (!access_token || !role) {
                throw new Error('Invalid response from server');
            }

            localStorage.setItem('token', access_token);
            localStorage.setItem('role', role);
            
            showToast('Login successful!');

            // Redirect based on role
            const redirectPath = role === 'teacher' ? '/teacher/home' : '/principal/home';
            window.location.href = redirectPath;

        } catch (error) {
            console.error('Login failed:', error);
            showToast(
                error.response?.data?.message || 'Login failed. Please try again.',
                true
            );
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container">
            <div className="heading">Teacher Log In</div>
            <form className="form" onSubmit={handleSubmit}>
                <div>
                    <input
                        placeholder="Username"
                        id="username"
                        name="username"
                        type="text"
                        className="input"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="username"
                    />
                    {errors.username && <p className="error-text">{errors.username}</p>}
                </div>
                <div className="password-container">
                    <input
                        placeholder="Password"
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        className="input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                    <span
                        className="visibility-icon"
                        onClick={togglePasswordVisibility}
                        role="button"
                        tabIndex={0}
                    >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </span>
                </div>
                {errors.password && <p className="error-text">{errors.password}</p>}
                <input value="Log In" type="submit" className="login-button" />
            </form>
        </div>
    );
}

export default Login;
