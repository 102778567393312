import React from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

// Import exam card icon
import exams_card_icon from '../../Images/exam_card_icon.jpg';

const ExamCard = ({ date, time, subject, examType, exam_id, stats }) => {
    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const handleStartExam = () => {
        if (examType === 'Upcoming') {
            showToast('This exam is not yet available', true);
            return;
        }
        
        if (!stats.totalQuestions || !stats.timeLimit) {
            showToast('Exam details are incomplete', true);
            return;
        }
    };

    return (
        <div className="studen-exxam-card">
            <div className="stuuden-exxam-card">
                <div className="card-header">
                    <p>Date: {date || 'Not specified'}</p>
                    <span className="status">
                        {time || 'Time not set'}
                    </span>
                </div>
                <div className="card-body">
                    <div className='sub'>
                        <div className="subject-info">
                            <div className="subject-icon">
                                <img
                                    src={exams_card_icon}
                                    alt={`${subject} Icon`}
                                    onError={(e) => {
                                        showToast('Failed to load subject icon', true);
                                    }}
                                />
                            </div>
                            <div>
                                <h3>{subject || 'Unknown Subject'}</h3>
                                <p>{examType || 'Status not available'}</p>
                            </div>
                        </div>
                    </div>
                    <hr style={{ border: "1px solid #000", margin: "20px 0" }} />
                    <div className="stu-e-stats">
                        <div className="stat-item">
                            <p>{stats?.totalQuestions || 0}</p>
                            <span>Total Questions</span>
                        </div>
                        <div className="stat-item">
                            <p>{stats?.timeLimit || 0} Min(s)</p>
                            <span>Time Limit</span>
                            <div className="vertical-line" style={{
                                marginLeft:"10px"
                            }}></div>
                        </div>
                        <div className="stat-item">
                            <Link 
                                to={`/student/exam?id=${exam_id}`} 
                                style={{textDecoration: 'none'}}
                                onClick={handleStartExam}
                            >
                                <button 
                                    className={`start-btn ${examType === 'Upcoming' ? 'disabled' : ''}`}
                                    disabled={examType === 'Upcoming'}
                                    aria-label={examType === 'Upcoming' ? 'Exam not yet available' : 'Start exam'}
                                >
                                    {examType === 'Upcoming' ? 'Upcoming' : 'Get Started'} 
                                    {examType !== 'Upcoming' && <KeyboardArrowRightOutlinedIcon/>}
                                </button>
                            </Link>
                            <div className="vertical-line" style={{
                                marginLeft:"30px"
                            }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamCard;