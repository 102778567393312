import React,{useState} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import '../../Css/Navbar.css';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleLogout = () => {
        try {
            localStorage.clear();
            Toastify({
                text: "Logged out successfully",
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
            }).showToast();
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
            Toastify({
                text: "Error logging out. Please try again.",
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: "linear-gradient(to right, #ff6b6b, #ff4757)",
                },
            }).showToast();
        }
    };

    return (
        <>
            <header className="navbar neu">
                <nav className="navigation">
                    <Link to="/student/home" style={{ textDecoration: 'none' }}>
                        <h1 className="app-title">
                            <span style={{ fontSize: '60px' }}>E</span>
                            <span style={{ fontSize: '50px' }}>xams</span>
                        </h1>
                    </Link>
                    <button className="hamburger" onClick={toggleMenu}>
                        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                    </button>
                    <ol className={`menu ${isMenuOpen ? 'open' : ''}`}>
                        <li className={`menu-items ${location.pathname === '/student/home' ? 'active' : ''}`}>
                            <Link to="/student/home" onClick={() => setIsMenuOpen(false)}>Home</Link>
                        </li>
                        <li className={`menu-items ${location.pathname === '/student/my-exams' ? 'active' : ''}`}>
                            <Link to="/student/my-exams" onClick={() => setIsMenuOpen(false)}>Exams</Link>
                        </li>
                        <li className="menu-items logout-item">
                            <button className="logout-button" onClick={handleLogout}>
                                <LogoutIcon style={{ fontSize: '15px' }} /> Log out
                            </button>
                        </li>
                    </ol>
                </nav>
            </header>
        </>
    );
}

export default Navbar;
