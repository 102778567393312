import React, { useEffect, useState } from 'react';

const Timer = ({ duration, onBlink, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');

  // Validate duration prop
  useEffect(() => {
    if (!duration || duration <= 0) {
      console.error("Invalid timer duration provided");
      return;
    }
    setTimeLeft(duration * 60); // Convert minutes to seconds
  }, [duration]);

  const tenPercentTime = (duration * 60) * 0.1;

  useEffect(() => {
    if (timeLeft === 0) {
      onTimerEnd();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 0) return 0;
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onTimerEnd]);

  useEffect(() => {
    if (timeLeft <= tenPercentTime && timeLeft > 0) {
      const message = `Time is running out! Only ${Math.floor(timeLeft / 60)} minutes left!`;
      setWarningMessage(message);
      onBlink();
    } else {
      setWarningMessage('');
    }
  }, [timeLeft, tenPercentTime, onBlink]);

  const formatTime = (time) => {
    try {
      if (isNaN(time) || time < 0) return "00 : 00 : 00";

      const hours = String(Math.floor(time / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
      const seconds = String(time % 60).padStart(2, '0');

      return `${hours} : ${minutes} : ${seconds}`;
    } catch (error) {
      console.error('Error formatting time:', error);
      return "00 : 00 : 00";
    }
  };

  const circleColor = timeLeft <= tenPercentTime ? 'red' : 'green';

  return (
    <div className="timer-container">
      <p className="timer-label">Finish Before</p>
      <div 
        className={`circle ${timeLeft <= tenPercentTime ? 'blinking' : ''}`} 
        style={{ borderColor: circleColor }}
        role="timer"
        aria-label={`Time remaining: ${formatTime(timeLeft)}`}
      >
        <div className="time">{formatTime(timeLeft)}</div>
      </div>
      {warningMessage && (
        <div className="warning-message" role="alert">
          {warningMessage}
        </div>
      )}
    </div>
  );
};

export default Timer;
