import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import '../../Css/ClassData.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function TeachersData() {
    const [teachers, setTeachers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [selectedTeacherToModify, setSelectedTeacherToModify] = useState('');
    const [transferData, setTransferData] = useState({
        teacher1: '',
        teacher2: '',
        subject: '',
        class_name: ''
    });

    const isMounted = useRef(true);

    const initialTeacherState = {
        username: '',
        password: '',
        name: '',
        phone: '',
        classes: '',
        subjects: '',
        class_teacher: '',
        age: '',
        gender: ''
    };

    const [modifiedTeacher, setModifiedTeacher] = useState(initialTeacherState);
    const [newTeacher, setNewTeacher] = useState(initialTeacherState);

    const showToast = (message, isError = false) => {
        // 
            Toastify({
                text: message,
                duration: 3000,
                gravity: "top",
                position: "right",
                close: true,
                style: {
                    background: isError 
                        ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                        : "linear-gradient(to right, #00b09b, #96c93d)",
                },
            }).showToast();
        // }
    };

    const validateTeacherData = (data) => {
        if (data.phone.length !== 10 || isNaN(data.phone)) {
            showToast("Phone number must be exactly 10 digits.", true);
            return false;
        }
        if (data.age < 21 || data.age > 65) {
            showToast("Age must be between 21 and 65 years.", true);
            return false;
        }
        if (!data.name.trim()) {
            showToast("Name is required.", true);
            return false;
        }
        if (!data.classes.trim()) {
            showToast("Classes are required.", true);
            return false;
        }
        if (!data.subjects.trim()) {
            showToast("Subjects are required.", true);
            return false;
        }
        return true;
    };

    const filteredTeachers = teachers.filter(teacher =>
        teacher.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        teacher.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleTransferChange = (e) => {
        const { name, value } = e.target;
        setTransferData({ ...transferData, [name]: value });
    };

    const handleTransferSubmit = async (e) => {
        e.preventDefault();
        try {
            if (transferData.teacher1 === transferData.teacher2) {
                showToast('Source and target teachers cannot be the same.', true);
                return;
            }
            await axiosInstance.post('/teacher/transfer-subject', transferData);
            // 
                showToast('Subject transferred successfully!');
                setIsTransferModalOpen(false);
                const response = await axiosInstance.get('/teacher/all-teachers');
                setTeachers(response.data);
                setTransferData({
                    teacher1: '',
                    teacher2: '',
                    subject: '',
                    class_name: ''
                });
            // }
            // setlo
        } catch (error) {
            console.error('Failed to transfer subject:', error);
            showToast(error.response?.data?.message || 'Failed to transfer subject. Please try again.', true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTeacher({ ...newTeacher, [name]: value });
    };

    const handleModifyInputChange = (e) => {
        const { name, value } = e.target;
        setModifiedTeacher({ ...modifiedTeacher, [name]: value });
    };

    const handleTeacherSelect = (e) => {
        const selectedUsername = e.target.value;
        const selectedTeacher = teachers.find(t => t.username === selectedUsername);
        if (selectedTeacher) {
            setSelectedTeacherToModify(selectedUsername);
            setModifiedTeacher({
                ...selectedTeacher,
                password: '',
                classes: selectedTeacher.classes.join(','),
                subjects: selectedTeacher.subjects.join(',')
            });
        }
    };

    const handleModifyTeacher = async (e) => {
        e.preventDefault();

        if (!validateTeacherData(modifiedTeacher)) {
            return;
        }

        try {
            const dataToSend = {
                ...modifiedTeacher,
                classes: modifiedTeacher.classes.split(',').map(c => c.trim()).filter(Boolean),
                subjects: modifiedTeacher.subjects.split(',').map(s => s.trim()).filter(Boolean)
            };
            
            if (!modifiedTeacher.password) {
                delete dataToSend.password;
            }

            await axiosInstance.put(`/teacher/modify-teacher/${selectedTeacherToModify}`, dataToSend);
            
                const response = await axiosInstance.get('/teacher/all-teachers');
                setTeachers(response.data);
                showToast('Teacher modified successfully!');
                setIsModifyModalOpen(false);
                setSelectedTeacherToModify('');
                setModifiedTeacher(initialTeacherState);
            
        } catch (error) {
            console.error('Failed to modify teacher:', error);
            showToast(error.response?.data?.message || 'Failed to modify teacher. Please try again.', true);
        }
    };

    const handleAddTeacher = async (e) => {
        e.preventDefault();

        if (!validateTeacherData(newTeacher)) {
            return;
        }

        try {
            const dataToSend = {
                ...newTeacher,
                classes: newTeacher.classes.split(',').map(c => c.trim()).filter(Boolean),
                subjects: newTeacher.subjects.split(',').map(s => s.trim()).filter(Boolean)
            };

            await axiosInstance.post('/create-teacher', dataToSend);
            
                const response = await axiosInstance.get('/teacher/all-teachers');
                setTeachers(response.data);
                showToast('Teacher added successfully!');
                setIsModalOpen(false);
                setNewTeacher(initialTeacherState);
            
        } catch (error) {
            console.error('Failed to add teacher:', error);
            showToast(error.response?.data?.message || 'Failed to add teacher. Please try again.', true);
        }
    };

    const handleSelect = (username) => {
        setSelectedTeachers(prev => {
            if (prev.includes(username)) {
                return prev.filter(id => id !== username);
            }
            return [...prev, username];
        });
    };

    const handleSelectAll = (e) => {
        setSelectedTeachers(e.target.checked ? filteredTeachers.map(teacher => teacher.username) : []);
    };

    const handleRemoveSelected = async () => {
        if (!selectedTeachers.length) {
            showToast('Please select teachers to remove', true);
            return;
        }

        try {
            await Promise.all(
                selectedTeachers.map(username => 
                    axiosInstance.delete(`/teacher/delete-teacher/${username}`)
                )
            );
            
            
                const response = await axiosInstance.get('/teacher/all-teachers');
                setTeachers(response.data);
                setSelectedTeachers([]);
                showToast('Selected teachers removed successfully!');
            
        } catch (error) {
            console.error('Failed to remove teachers:', error);
            showToast(error.response?.data?.message || 'Failed to remove teachers. Please try again.', true);
        }
    };
    useEffect(() => {
        let isMounted = true;
        
        const getTeachers = async () => {
            try {
                const response = await axiosInstance.get('/teacher/all-teachers');
                if (!response?.data) {
                    throw new Error('No data received from server');
                }
                if (isMounted) {
                    setTeachers(response.data);
                    showToast('Teachers loaded successfully');
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching teachers:', error);
                    showToast(error.response?.data?.message || 'Failed to fetch teachers', true);
                }
            }
        };
        
        getTeachers();
        
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name')}</span>
                    <p className="text">Manage Your Teachers!</p>
                </div>
                <div className="cls-button">
                    <button className="cls-add-btn" onClick={() => setIsModalOpen(true)}>+ Add Teacher</button>
                    <button className="cls-add-btn" onClick={() => setIsModifyModalOpen(true)}>Modify Teacher</button>
                    <button className="cls-add-btn" onClick={() => setIsTransferModalOpen(true)}>Transfer Data</button>
                    <button
                        className="cls-remove-btn"
                        onClick={handleRemoveSelected}
                        disabled={selectedTeachers.length === 0}
                    >
                        - Remove Selected
                    </button>
                </div>
                <div className="students">
                    <div className="cls-stu-inner">
                        <h5>Your Teachers</h5>
                    </div>
                    <input
                        type="text"
                        placeholder="Search teachers by name or username..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-box"
                    />
                   <div className="table-block">
                   <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={selectedTeachers.length === filteredTeachers.length && filteredTeachers.length > 0}
                                    />
                                </th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Classes</th>
                                <th>Class Teacher</th>
                                <th>Subject</th>
                                <th>Age</th>
                                <th>Gender</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTeachers.map((teacher) => (
                                <tr key={teacher.username}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedTeachers.includes(teacher.username)}
                                            onChange={() => handleSelect(teacher.username)}
                                        />
                                    </td>
                                    <td>
                                        <Link
                                            to={`/principal/teacher?username=${teacher.username}`}
                                            className="link-row"
                                            style={{
                                                color: '#3366ff',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                                fontWeight: 500,
                                                ':hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            {teacher.name}
                                        </Link>
                                    </td>
                                    <td>{teacher.phone}</td>
                                    <td>{teacher.classes.join(', ')}</td>
                                    <td>{teacher.class_teacher}</td>
                                    <td>{teacher.subjects.join(', ')}</td>
                                    <td>{teacher.age}</td>
                                    <td>{teacher.gender}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   </div>
                    {filteredTeachers.length === 0 && <p>No teachers found.</p>}
                </div>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Add New Teacher</h3>
                        <form onSubmit={handleAddTeacher}>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={newTeacher.username}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={newTeacher.password}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={newTeacher.name}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone Number"
                                value={newTeacher.phone}
                                onChange={handleInputChange}
                                maxLength={10}
                                required
                            />
                            <input
                                type="text"
                                name="classes"
                                placeholder="Classes (comma separated)"
                                value={newTeacher.classes}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="subjects"
                                placeholder="Subjects (comma separated)"
                                value={newTeacher.subjects}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="class_teacher"
                                placeholder="Class Teacher"
                                value={newTeacher.class_teacher}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="number"
                                name="age"
                                placeholder="Age"
                                value={newTeacher.age}
                                onChange={handleInputChange}
                                required
                            />
                            <select
                                name="gender"
                                value={newTeacher.gender}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="save-btn">Add Teacher</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isModifyModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Modify Teacher</h3>
                        <form onSubmit={handleModifyTeacher}>
                            <select
                                value={selectedTeacherToModify}
                                onChange={handleTeacherSelect}
                                required
                            >
                                <option value="">Select Teacher</option>
                                {teachers.map(teacher => (
                                    <option key={teacher.username} value={teacher.username}>
                                        {teacher.name} ({teacher.username})
                                    </option>
                                ))}
                            </select>
                            {selectedTeacherToModify && (
                                <>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        value={modifiedTeacher.username}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="New Password (leave empty to keep unchanged)"
                                        value={modifiedTeacher.password}
                                        onChange={handleModifyInputChange}
                                    />
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={modifiedTeacher.name}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone Number"
                                        value={modifiedTeacher.phone}
                                        onChange={handleModifyInputChange}
                                        maxLength={10}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="classes"
                                        placeholder="Classes (comma separated)"
                                        value={modifiedTeacher.classes}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="subjects"
                                        placeholder="Subjects (comma separated)"
                                        value={modifiedTeacher.subjects}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="class_teacher"
                                        placeholder="Class Teacher"
                                        value={modifiedTeacher.class_teacher}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <input
                                        type="number"
                                        name="age"
                                        placeholder="Age"
                                        value={modifiedTeacher.age}
                                        onChange={handleModifyInputChange}
                                        required
                                    />
                                    <select
                                        name="gender"
                                        value={modifiedTeacher.gender}
                                        onChange={handleModifyInputChange}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </>
                            )}
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsModifyModalOpen(false);
                                        setSelectedTeacherToModify('');
                                        setModifiedTeacher(initialTeacherState);
                                    }}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button 
                                    type="submit" 
                                    className="save-btn"
                                    disabled={!selectedTeacherToModify}
                                >
                                    Modify Teacher
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isTransferModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Transfer Subject Data</h3>
                        <form onSubmit={handleTransferSubmit}>
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div style={{ flex: 1 }}>
                                    <select
                                        name="teacher1"
                                        value={transferData.teacher1}
                                        onChange={handleTransferChange}
                                        required
                                    >
                                        <option value="">Select Source Teacher</option>
                                        {teachers.map(teacher => (
                                            <option key={teacher.username} value={teacher.username}>
                                                {teacher.name} ({teacher.username})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <select
                                        name="teacher2"
                                        value={transferData.teacher2}
                                        onChange={handleTransferChange}
                                        required
                                    >
                                        <option value="">Select Target Teacher</option>
                                        {teachers.map(teacher => (
                                            <option key={teacher.username} value={teacher.username}>
                                                {teacher.name} ({teacher.username})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {transferData.teacher1 && (
                                <>
                                    <select
                                        name="subject"
                                        value={transferData.subject}
                                        onChange={handleTransferChange}
                                        required
                                    >
                                        <option value="">Select Subject</option>
                                        {teachers.find(t => t.username === transferData.teacher1)?.subjects.map(subject => (
                                            <option key={subject} value={subject}>
                                                {subject}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        name="class_name"
                                        value={transferData.class_name}
                                        onChange={handleTransferChange}
                                        required
                                    >
                                        <option value="">Select Class</option>
                                        {teachers.find(t => t.username === transferData.teacher1)?.classes.map(className => (
                                            <option key={className} value={className}>
                                                {className}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}
                            <div className="modal-actions">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsTransferModalOpen(false);
                                        setTransferData({
                                            teacher1: '',
                                            teacher2: '',
                                            subject: '',
                                            class_name: ''
                                        });
                                    }}
                                    className="cancel-btn"
                                >
                                    Cancel
                                </button>
                                <button 
                                    type="submit" 
                                    className="save-btn"
                                    disabled={!transferData.teacher1 || !transferData.teacher2 || !transferData.subject || !transferData.class_name}
                                >
                                    Transfer Data
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default TeachersData;
