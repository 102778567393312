import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import '../../Css/TeacherHome.css';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ai from '../../Images/Ai.png';
import nonai from '../../Images/nonai.png';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Home() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClass, setSelectedClass] = useState('All');
    const [teacherData, setTeacherData] = useState({
        teacher_name: '',
        role: '',
        stats: {
            total_students: 0,
            total_exams: 0,
            running_exams: 0,
            upcoming_exams: 0
        }
    });
    const navigate = useNavigate();

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            }
        }).showToast();
    };

    const cards = [
        { id: 1, label: 'Total Students', value: teacherData.stats.total_students, icon: <PersonOutlineOutlinedIcon style={{ color: '#007bff' }} /> },
        { id: 2, label: 'Total Exams', value: teacherData.stats.total_exams, icon: <FlagOutlinedIcon style={{ color: '#28a745' }} /> },
        { id: 3, label: 'Running Exams', value: teacherData.stats.running_exams, icon: <AssignmentOutlinedIcon style={{ color: '#ffc107' }} /> },
        { id: 4, label: 'Upcoming Exams', value: teacherData.stats.upcoming_exams, icon: <EventOutlinedIcon style={{ color: '#dc3545' }} /> }
    ];

    const classesData = {
        'All': {
            students: 300,
            examsConducted: 120,
            runningExam: 80,
            upcomingExams: 40,
        },
        'Class 1st': {
            students: 30,
            examsConducted: 10,
            runningExam: 6,
            upcomingExams: 4,
        },
        'Class 2nd': {
            students: 28,
            examsConducted: 12,
            runningExam: 7,
            upcomingExams: 5,
        },
        'Class 3rd': {
            students: 25,
            examsConducted: 15,
            runningExam: 10,
            upcomingExams: 5,
        },
        'Class 4th': {
            students: 30,
            examsConducted: 20,
            runningExam: 12,
            upcomingExams: 8,
        },
        'Class 5th': {
            students: 32,
            examsConducted: 15,
            runningExam: 9,
            upcomingExams: 6,
        },
        'Class 6th': {
            students: 29,
            examsConducted: 18,
            runningExam: 10,
            upcomingExams: 8,
        },
        'Class 7th': {
            students: 35,
            examsConducted: 20,
            runningExam: 12,
            upcomingExams: 8,
        },
        'Class 8th': {
            students: 40,
            examsConducted: 25,
            runningExam: 15,
            upcomingExams: 10,
        },
        'Class 9th': {
            students: 38,
            examsConducted: 22,
            runningExam: 14,
            upcomingExams: 8,
        },
        'Class 10th': {
            students: 43,
            examsConducted: 28,
            runningExam: 18,
            upcomingExams: 10,
        },
    };
    
    const stats = classesData[selectedClass];

    const [students] = useState([
        'John Doe',
        'Jane Smith', 
        'Mike Johnson',
        'Emily Davis',
        'Chris Brown',
        'Harsha Vardhan',
    ]);

    const filteredStudents = students.filter(student =>
        student.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClassClick = (cls) => {
        try {
            setSelectedClass(cls);
            if (cls !== 'All') {
                navigate('/principal/class-data', { state: { selectedClass: cls } });
            }
        } catch (error) {
            console.error('Error navigating to class data:', error);
            showToast('Failed to navigate to class data', true);
        }
    };

    useEffect(() => {
        let isMounted = true;
        
        const fetchTeacherData = async () => {
            try {
                const response = await axiosInstance.get('/teacher/teacher-home');
                if (!response?.data) {
                    throw new Error('No data received from server');
                }
                if (isMounted) {
                    setTeacherData(response.data);
                    localStorage.setItem('name', response.data.teacher_name);
                    showToast('Principal data loaded successfully');
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching principal data:', error);
                    showToast('Failed to load principal data', true);
                }
            }
        };
        
        fetchTeacherData();
        
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <Navbar/>
            <div className="teacher-home">
                <div className="welcome-section">
                    <div>
                        <span className="welcome-name">Hello, {teacherData.teacher_name}</span>
                        <p className="text">Welcome back to Exams!</p>
                    </div>
                    <div className="search-section">
                        <input
                            type="text"
                            className="search-bar"
                            placeholder="Search students..."
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                        {searchQuery && (
                            <ul className="search-results-dropdown">
                                {filteredStudents.length > 0 ? (
                                    filteredStudents.map((student, index) => (
                                        <li key={index} className="search-result-item">
                                            {student}
                                        </li>
                                    ))
                                ) : (
                                    <li className="search-result-item">No students found.</li>
                                )}
                            </ul>
                        )}
                    </div>
                </div>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '2em' }}>My Classes</h3>
                    <div className="class-buttons">
                        {Object.keys(classesData).map(cls => (
                            <button
                                key={cls}
                                className={`cls-btn ${selectedClass === cls ? 'active' : ''}`}
                                onClick={() => handleClassClick(cls)}
                            >
                                {cls}
                            </button>
                        ))}
                    </div>
                </section>
                <section className="overview-sction">
                    <span className="over">Overview of your exams and students</span>
                    <div className="stu-container">
                        {cards.map((stat) => (
                            <div key={stat.id} className="stu-card">
                                <div className="stu-icon">
                                    <div className="stu-circle-ico">
                                        {stat.icon}
                                    </div>
                                </div>
                                <div className="stu-space">
                                    <div className="stu-value">{stat.value}</div>
                                    <div className="stu-label">{stat.label}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="start-exam-section">
                    <div className="exam-option">
                        <h3>Start a new exam with AI</h3>
                        <div className="ai-img-block">
                            <img src={ai} alt="AI Exam Generation" className='ai-img' />
                        </div>
                        <Link to='/principal/generate-exam-with-ai' style={{textDecoration: 'none'}}>
                            <button className="generate-button">
                                <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} />
                            </button>
                        </Link>
                    </div>
                    <div className="exam-option">
                        <h3>Start a new exam manually</h3>
                        <div className="nonai-img-block">
                            <img src={nonai} alt="Manual Exam Generation" className='nonai-img' />
                        </div>
                        <Link to='/principal/generate-exam' style={{textDecoration: 'none'}}>
                            <button className="generate-button">
                                <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} />
                            </button>
                        </Link>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Home;
