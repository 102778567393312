import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from './Navbar';
import '../../Css/TeacherHome.css';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ai from '../../Images/Ai.png'
import nonai from '../../Images/nonai.png'
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function Home() {
    const [selectedClass, setSelectedClass] = useState('All');
    const navigate = useNavigate();
    const [classesData, setClassesData] = useState({});
    const [cards, setCards] = useState([]);
    const [subjects,setSubjects] = useState([]);
    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff6b6b, #ff4757)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            },
        }).showToast();
    };

    const handleClassClick = (cls) => {
        setSelectedClass(cls);
        if (cls !== 'All') {
            navigate('/teacher/class-data', { state: { selectedClass: cls } });
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/teacher/teacher-home');
                
                if (!isMounted) return;

                if (!response.data || !response.data.classes_data) {
                    throw new Error('Invalid data received from server');
                }

                setClassesData(response.data.classes_data);
                
                const allClassData = response.data.classes_data['All'];
                if (!allClassData) {
                    throw new Error('Missing All class data');
                }

                setCards([
                    { id: 1, label: 'Total Students', value: allClassData.students || 0, icon: <PersonOutlineOutlinedIcon style={{ color: '#007bff' }} /> },
                    { id: 2, label: 'Exams Conducted', value: allClassData.examsConducted || 0, icon: <FlagOutlinedIcon style={{ color: '#28a745' }} /> },
                    { id: 3, label: 'Running Exams', value: allClassData.runningExam || 0, icon: <AssignmentOutlinedIcon style={{ color: '#ffc107' }} /> },
                    { id: 4, label: 'Upcoming Exams', value: allClassData.upcomingExams || 0, icon: <EventOutlinedIcon style={{ color: '#dc3545' }} /> }
                ]);
                
                if (response.data.teacher_name) {
                    localStorage.setItem('name', response.data.teacher_name);
                }
                if (response.data.class_teacher) {
                    localStorage.setItem('cteacher', response.data.class_teacher);
                }

                if (isMounted) {
                    showToast('Data loaded successfully');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (isMounted) {
                    showToast(error.message || 'Failed to load teacher data', true);
                }
            }
        };
        const fetchSubjects = async () => {
            try {
                const response = await axiosInstance.get('/teacher/info');
                setSubjects(response.data.subjects);
            } catch (error) {
                console.error('Error fetching teacher info:', error);
                showToast('Failed to fetch teacher information', 'error');
            }
        };
        fetchData();
        fetchSubjects();

        return () => {
            isMounted = false;
        };
    }, []); // Add showToast to dependency array to prevent re-renders

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div className="welcome-section">
                    <div>
                        <span className="welcome-name">Hello, {localStorage.getItem('name') || 'Teacher'}</span>
                        <p className="text">Welcome back to Exams!</p>
                    </div>
                </div>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '2em' }}>My Classes</h3>
                    <div className="class-buttons">
                        {Object.keys(classesData).map(cls => (
                            <button
                                key={cls}
                                className={`cls-btn ${selectedClass === cls ? 'active' : ''}`}
                                onClick={() => handleClassClick(cls)}
                            >
                                {cls}
                            </button>
                        ))}
                    </div>
                </section>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '2em' }}>My Subjects</h3>
                    <div className="class-buttons">
                        {subjects.map(cls => (
                            <button
                                key={cls}
                                className={`cls-btn active`}
                                // onClick={() => handleClassClick(cls)}
                                disabled
                            >
                                {cls}
                            </button>
                        ))}
                    </div>
                </section>
                <section className="overview-sction">
                    <span className="over">Overview of your exams and students</span>
                    <div className="stu-container">
                        {cards.map((stat) => (
                            <div key={stat.id} className="stu-card">
                                <div className="stu-icon">
                                    <div className="stu-circle-ico">
                                        {stat.icon}
                                    </div>
                                </div>
                                <div className="stu-space">
                                    <div className="stu-value">{stat.value}</div>
                                    <div className="stu-label">{stat.label}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="start-exam-section">
                    <div className="exam-option">
                        <h3>Start a new exam with AI</h3>
                        <div className="ai-img-block">
                            <img src={ai} className='ai-img' alt="AI exam" />
                        </div>
                        <Link to='/teacher/generate-exam-with-ai' style={{textDecoration: 'none'}}>
                            <button className="generate-button">
                                <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} />
                            </button>
                        </Link>
                    </div>
                    <div className="exam-option">
                        <h3>Start a new exam manually</h3>
                        <div className="ai-img-block">
                            <img src={ai} className='ai-img' alt="AI exam" />
                        </div>
                        <Link to='/teacher/generate-exam' style={{textDecoration: 'none'}}>
                            <button className="generate-button">
                                <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} />
                            </button>
                        </Link>
                    </div>
                </section>
                <section>
                    .
                </section>
            </div>
        </>
    );
}

export default Home;
