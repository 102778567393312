import { useState, useEffect } from 'react';
import React from 'react';
import Navbar from './Navbar';
import '../../Css/StuMyExams.css';
import telugu from '../../Images/telugu.jpg';
import hindi from '../../Images/hindi.jpg';
import english from '../../Images/english.jpg';
import maths from '../../Images/maths.jpg';
import physics from '../../Images/ps.jpg';
import biology from '../../Images/biology.jpg';
import social from '../../Images/socialstudies.jpg';
import science from '../../Images/science.jpg';
import StudentExamCard from './StudentExamCard';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function MyExams() {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const [examsData, setExamsData] = useState({});
  const [loading, setLoading] = useState(true);

  const showToast = (message, isError = false) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: "top",
      position: "right",
      close: true,
      style: {
        background: isError 
          ? "linear-gradient(to right, #ff6b6b, #ff4757)"
          : "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
  };

  useEffect(() => {
    let isMounted = true;

    const fetchExams = async () => {
      try {
        const response = await axiosInstance('/student-exam/get-all-exams');
        if (!isMounted) return;

        if (!response?.data) {
          throw new Error('No exam data received');
        }
        setExamsData(response.data);
        if (isMounted) {
          showToast('Exams loaded successfully');
        }
      } catch (error) {
        console.error('Error fetching exams:', error);
        if (isMounted) {
          showToast(
            error.response?.data?.message || 'Failed to load exams. Please try again.',
            true
          );
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchExams();

    return () => {
      isMounted = false;
    };
  }, []);

  const subjectIcons = {
    telugu,
    hindi,
    english,
    maths,
    physics,
    biology,
    social,
    science,
  };

  const allSubjects = Object.keys(examsData);

  const calculateSubjectCounts = () => {
    return Object.entries(examsData).reduce((counts, [subject, exams]) => {
      counts[subject] = exams.length;
      return counts;
    }, {});
  };

  const subjectCounts = calculateSubjectCounts();

  const subjects = allSubjects.map(subject => ({
    name: subject,
    count: subjectCounts[subject] || 0,
  }));

  const getFilteredExams = () => {
    const searchTerm = searchQuery.toLowerCase().trim();
    
    if (activeFilter === 'All') {
      return Object.entries(examsData).flatMap(([subject, exams]) =>
        exams.filter(() => 
          subject.toLowerCase().includes(searchTerm)
        ).map(exam => ({...exam, subject}))
      );
    }
    
    return (examsData[activeFilter] || []).map(exam => ({
      ...exam,
      subject: activeFilter
    }));
  };

  const filteredExams = getFilteredExams();
  const totalExams = Object.values(examsData).reduce((acc, exams) => acc + exams.length, 0);

  if (loading) {
    return <div className="loading-container">Loading exams...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="teacher-home">
        <div className="welcome-section">
          <div>
            <span className="welcome-name">Hello, {localStorage.getItem('name') || 'Student'}</span>
            <p className="text">Welcome back to Exams!</p>
          </div>
          <div className="search-section">
            <input
              type="text"
              className="search-bar"
              placeholder="Search exams..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search exams"
            />
          </div>
        </div>
        <div className="exam-stats-container">
          <h3>Total exams: {totalExams}</h3>
          <div className="subjects-grid">
            <div
              className={`subject-card ${activeFilter === 'All' ? 'active' : ''}`}
              onClick={() => setActiveFilter('All')}
              role="button"
              tabIndex={0}
            >
              <div className="subject-icon">
                <span className="subject-name">All</span>
              </div>
            </div>
            {subjects.map((subject) => (
              <div
                className={`subject-card ${activeFilter === subject.name ? 'active' : ''}`}
                key={subject.name}
                onClick={() => setActiveFilter(subject.name)}
                role="button"
                tabIndex={0}
              >
                <div className="subject-icon">
                  <img
                    src={subjectIcons[subject.name]}
                    alt={`${subject.name} icon`}
                    className="subject-image"
                  />
                </div>
                <div className="subject-details">
                  <span className="subject-name">{subject.name}</span>
                  <span className="subject-count">
                    {subject.count} {subject.count === 1 ? 'exam' : 'exams'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="exam-list">
          {filteredExams.length > 0 ? (
            filteredExams.map((exam) => (
              <StudentExamCard 
                key={exam.exam_id} 
                {...exam}
                examStats={{
                  totalQuestions: exam.total_questions,
                  scheduledTime: exam.scheduled_time,
                  scheduledDate: exam.exam_date,
                  duration: exam.duration,
                  status: exam.status,
                  exam_id: exam.exam_id,
                  exam_icon: exam.subject.toLowerCase()
                }}
              />
            ))
          ) : (
            <p className="no-exams-message">No exams available for the selected subject.</p>
          )}
        </div>
      </div>
    </>
  );
}

export default MyExams;
