import React, { useEffect, useState } from 'react';
import '../../Css/GenerateExamWithAi.css';
import Navbar from './Navbar';
import CallMadeIcon from '@mui/icons-material/CallMade';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function GenerateExamWithAi() {
    const initialFormState = {
        subject: '',
        extraTopics: '',
        grade: '',
        timings: '',
        examDate: '',
        numMCQs: '',
        numDescriptive: '',
        duration: ''
    };

    const [formData, setFormData] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const showToast = (message, isError = false) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: isError 
                    ? "linear-gradient(to right, #ff0000, #ff5f6d)"
                    : "linear-gradient(to right, #00b09b, #96c93d)",
            }
        }).showToast();
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const validate = () => {
        const newErrors = {};
        if (!formData.subject.trim()) newErrors.subject = 'Subject is required.';
        if (!formData.extraTopics.trim()) newErrors.extraTopics = 'Extra topics are required.';
        if (!formData.duration.trim() || parseInt(formData.duration) <= 0) {
            newErrors.duration = 'Duration must be greater than 0.';
        }
        if (!formData.grade) newErrors.grade = 'Grade is required.';
        if (!formData.timings.trim()) newErrors.timings = 'Timings are required.';
        if (!formData.examDate) newErrors.examDate = 'Exam date is required.';
        if (!formData.numMCQs || parseInt(formData.numMCQs) <= 0) {
            newErrors.numMCQs = 'Number of MCQs must be greater than 0.';
        }
        if (!formData.numDescriptive || parseInt(formData.numDescriptive) <= 0) {
            newErrors.numDescriptive = 'Number of descriptive questions must be greater than 0.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'numMCQs' || name === 'numDescriptive' || name === 'duration') && value < 0) {
            return;
        }
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!validate()) {
                showToast('Please fill all required fields correctly', true);
                return;
            }

            const response = await axiosInstance.post('/exam/generate-questions', {
                subjects: formData.subject,
                prompt: formData.extraTopics,
                standard: formData.grade,
                num_mcqs: parseInt(formData.numMCQs),
                num_desc: parseInt(formData.numDescriptive),
            });

            setQuestions(response.data.questions);
            showToast('Questions generated successfully!');
        } catch (error) {
            console.error('Error generating questions:', error);
            showToast(error.response?.data?.message || 'Failed to generate questions', true);
        } finally {
            setLoading(false);
        }
    };

    const handleFinalSubmit = async () => {
        try {
            const examData = {
                standard: formData.grade,
                num_mcqs: parseInt(formData.numMCQs),
                num_desc: parseInt(formData.numDescriptive),
                scheduled_time: formData.timings,
                scheduled_date: formData.examDate,
                questions: questions,
                subject: formData.subject,
                duration: parseInt(formData.duration)
            };

            const response = await axiosInstance.post('/exam/create-exam', examData);
            showToast('Exam created successfully!');
            setQuestions([]);
            setFormData(initialFormState);
            setSubmissionSuccess(true);
            setTimeout(() => setSubmissionSuccess(false), 3000);
        } catch (error) {
            console.error('Error creating exam:', error);
            showToast(error.response?.data?.message || 'Failed to create exam', true);
        }
    };

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const grades = Array.from({length: 10}, (_, i) => `${i + 1}`);
                setInfo(grades);
            } catch (error) {
                console.error('Error fetching grades:', error);
                showToast('Failed to load grades', true);
            }
        };
        fetchInfo();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Navbar />
            <div className="teacher-home">
                <div>
                    <span className="welcome-name">Hello, {localStorage.getItem('name')}</span>
                    <p className="text">Welcome back to Exams!</p>
                </div>
                <hr className="gea-divider" />
                <div className="create-exam-ai">
                    <div className="create-exam-ai-left">
                        <form className="gea-exam-form" noValidate>
                            <div className="gea-row">
                                <div className="gea-form-group">
                                    <label className="gea-label">Subject</label>
                                    <input
                                        className="gea-input"
                                        type="text"
                                        name="subject"
                                        placeholder="Enter Subject"
                                        value={formData.subject}
                                        onChange={handleInputChange}
                                    />
                                    {errors.subject && (
                                        <span className="error-message">{errors.subject}</span>
                                    )}
                                </div>
                                <div className="gea-form-group">
                                    <label className="gea-label">Extra topics</label>
                                    <input
                                        className="gea-input"
                                        type="text"
                                        name="extraTopics"
                                        placeholder="Enter Extra Topics"
                                        value={formData.extraTopics}
                                        onChange={handleInputChange}
                                    />
                                    {errors.extraTopics && (
                                        <span className="error-message">{errors.extraTopics}</span>
                                    )}
                                </div>
                            </div>
                            <div className="gea-row">
                                <div className="gea-form-group">
                                    <label className="gea-label">Grade</label>
                                    <select
                                        className="gea-select"
                                        name="grade"
                                        value={formData.grade}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Grade</option>
                                        {info.map((grade) => (
                                            <option key={grade} value={grade}>
                                                {grade}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.grade && (
                                        <span className="error-message">{errors.grade}</span>
                                    )}
                                </div>
                                <div className="gea-form-group">
                                    <label className="gea-label">Timings</label>
                                    <input
                                        className="gea-input"
                                        type="time"
                                        name="timings"
                                        value={formData.timings}
                                        onChange={handleInputChange}
                                    />
                                    {errors.timings && (
                                        <span className="error-message">{errors.timings}</span>
                                    )}
                                </div>
                            </div>
                            <div className="gea-row">
                                <div className="gea-form-group">
                                    <label className="gea-label">Date of exam</label>
                                    <input
                                        className="gea-input"
                                        type="date"
                                        name="examDate"
                                        value={formData.examDate}
                                        min={new Date().toISOString().split('T')[0]}
                                        onChange={handleInputChange}
                                    />
                                    {errors.examDate && (
                                        <span className="error-message">{errors.examDate}</span>
                                    )}
                                </div>
                                <div className="gea-form-group">
                                    <label className="gea-label">No. of MCQs</label>
                                    <input
                                        className="gea-input"
                                        type="number"
                                        name="numMCQs"
                                        min="0"
                                        value={formData.numMCQs}
                                        onChange={handleInputChange}
                                    />
                                    {errors.numMCQs && (
                                        <span className="error-message">{errors.numMCQs}</span>
                                    )}
                                </div>
                            </div>
                            <div className="gea-row">
                                <div className="gea-form-group">
                                    <label className="gea-label">No. of Descriptive</label>
                                    <input
                                        className="gea-input"
                                        type="number"
                                        name="numDescriptive"
                                        min="0"
                                        value={formData.numDescriptive}
                                        onChange={handleInputChange}
                                    />
                                    {errors.numDescriptive && (
                                        <span className="error-message">{errors.numDescriptive}</span>
                                    )}
                                </div>
                                <div className="gea-form-group">
                                    <label className="gea-label">Duration (minutes)</label>
                                    <input
                                        className="gea-input"
                                        type="number"
                                        name="duration"
                                        min="0"
                                        value={formData.duration}
                                        onChange={handleInputChange}
                                    />
                                    {errors.duration && (
                                        <span className="error-message">{errors.duration}</span>
                                    )}
                                </div>
                            </div>
                            <div className="gea-sub">
                                <button
                                    className="ge-generate-button"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Loading...' : <> <AutoAwesomeOutlinedIcon /> Generate Exam <CallMadeIcon style={{ fontSize: '15px' }} /> </>}
                                </button>
                            </div>
                        </form>
                        <div className="gea-vertical-line"></div>
                    </div>
                    <div className="create-exam-ai-right">
                        {questions.exam_paper && questions.exam_paper.mcqs && questions.exam_paper.mcqs.length > 0 ? (
                            <div className="gea-questions-container">
                                <div className="gea-questions-header">
                                    <h3 className="gea-questions-title">Generated Questions:</h3>
                                </div>
                                <ul className="gea-questions-list">
                                    {questions.exam_paper.mcqs.map((q, index) => (
                                        <li key={index} className="gea-question-item">
                                            <p>{q.question}</p>
                                            <ul>
                                                {q.options.map((opt, i) => (
                                                    <li key={i}>{opt}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                    {questions.exam_paper.description.map((q, index) => (
                                        <li key={index} className="gea-question-item">
                                            <p>{q.question}</p>
                                        </li>
                                    ))}
                                </ul>
                                <div className="gea-ques-sub">
                                    <button
                                        className="gea-sub-btn"
                                        onClick={handleFinalSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                                {submissionSuccess && (
                                    <p className="gea-submission-success">
                                        Questions submitted successfully!
                                    </p>
                                )}
                            </div>
                        ) : (
                            <p className="gea-no-questions">No questions generated yet.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenerateExamWithAi;
