import React, { useEffect, useState } from 'react';
import ExamCard from './ExamCard';
import Navbar from './Navbar';
import '../../Css/MyExams.css';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

function MyExams() {
    const [selectedClass, setSelectedClass] = useState('');
    const [classesData, setClassesData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const showErrorToast = (message) => {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            close: true,
            style: {
                background: "linear-gradient(to right, #ff6b6b, #ff8787)",
            },
        }).showToast();
    };

    const fetchExams = async (isMounted) => {
        try {
            if (!isMounted) return;
            setIsLoading(true);
            const response = await axiosInstance.get('/exam/get-exams');
            console.log(response.data)
            
            if (!isMounted) return;
            if (!response?.data) {
                throw new Error('No data received from server');
            }

            setClassesData(response.data);
            
            if (Object.keys(response.data).length > 0) {
                setSelectedClass(Object.keys(response.data)[0]);
            }
        } catch (error) {
            if (!isMounted) return;
            const errorMessage = error.response?.data?.message || 'Error fetching exams. Please try again later.';
            showErrorToast(errorMessage);
            console.error('Error fetching exams:', error);
        } finally {
            if (!isMounted) return;
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const loadExams = async () => {
            await fetchExams(isMounted);
        };

        loadExams();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleClassSelection = (cls) => {
        if (cls !== selectedClass) {
            setSelectedClass(cls);
        }
    };

    const renderExamList = () => {
        if (isLoading) {
            return <p style={{ textAlign: 'center', fontSize: 'larger' }}>Loading exams...</p>;
        }

        if (!Object.keys(classesData).length) {
            return <p style={{ textAlign: 'center', fontSize: 'larger' }}>No exams created yet.</p>;
        }

        return selectedClass && classesData[selectedClass]?.map((exam, index) => (
            <ExamCard key={`exam-${exam.id || index}`} data={exam} />
        ));
    };

    return (
        <>
            <Navbar />
            <div className="myexam-block teacher-home">
                <div className="welcome-section">
                    <div>
                    <span className="welcome-name">
                        Hello, {localStorage.getItem('name') || 'Teacher'}
                    </span>
                    <p className="text">Welcome back to Exams!</p>
                    </div>
                </div>
                <section className="classes-section">
                    <h3 style={{ color: '#1e4091', fontSize: '2em' }}>My Classes</h3>
                    <div className="class-buttons">
                        {Object.keys(classesData).map(cls => (
                            <button
                                key={`class-${cls}`}
                                className={`cls-btn ${selectedClass === cls ? 'active' : ''}`}
                                onClick={() => handleClassSelection(cls)}
                            >
                                {cls}
                            </button>
                        ))}
                    </div>
                </section>
                <div className="exam-list">
                    {renderExamList()}
                </div>
            </div>
        </>
    );
}

export default MyExams;
