import React, { useState, useEffect, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import "toastify-js/src/toastify.css";
import Toastify from 'toastify-js';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircleIcon from '@mui/icons-material/Circle';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EventNoteIcon from '@mui/icons-material/EventNote';
import QuizIcon from '@mui/icons-material/Quiz';

import Navbar from './Navbar';
import '../../Css/Data.css';

const TeacherData = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);

  const showToast = (message, isError = false) => {
    // 
      Toastify({
        text: message,
        duration: 3000,
        gravity: "top",
        position: "right",
        close: true,
        style: {
          background: isError 
            ? "linear-gradient(to right, #ff0000, #ff5f6d)"
            : "linear-gradient(to right, #00b09b, #96c93d)",
        }
      }).showToast();
    // }
  };

  useEffect(() => {
    let isMounted = true;
    
    const fetchTeacherData = async () => {
      try {
        if (!username) {
          throw new Error('Teacher username is required');
        }

        const response = await axiosInstance.get(`/teacher/details/${username}`);
        
        if (!response?.data) {
          throw new Error('No data received from server');
        }

        if (isMounted) {
          setTeacherData(response.data);
          showToast('Teacher data loaded successfully');
        }

      } catch (error) {
        if (isMounted) {
          console.error('Error fetching teacher data:', error);
          showToast(error.response?.data?.message || error.message, true);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchTeacherData();

    return () => {
      isMounted = false;
    };
  }, [username]);

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (!teacherData) {
    return <div className="no-data">No teacher data found</div>;
  }

  const { teacher_info, statistics, exams, students } = teacherData;

  return (
    <>
      <Navbar />
      <div className="teacher-home">
        <div className="hero">
          <div className="backarrow">
            <Link to="/principal/teachers-data">
              <KeyboardBackspaceIcon className="back-icon" />
            </Link>
          </div>
          <div className="teacher-name-status">
            <h2 className="teacher-name">{teacher_info?.name?.toUpperCase() || 'N/A'}</h2>
            <div className="status">
              <CircleIcon className="status-icon" />
              <span className="status-text">ACTIVE</span>
            </div>
          </div>
          <div className="vertical"></div>
          <div className="">
            <span className="label">USERNAME</span>
            <h2 className="username">{teacher_info?.username || 'N/A'}</h2>
          </div>
        </div>

        <div className="details-block">
          <div className="left-details">
            <div className="personal-details">
              <div className="header">
                <h2 className="section-title">
                  <PersonIcon />
                  PERSONAL INFORMATION
                </h2>
              </div>
              <div className="info-row">
                <div className="info">
                  <div className="pd1">
                    <span className="label">ROLE</span>
                    <h5 className="value">{teacher_info?.role || 'N/A'}</h5>
                  </div>
                  <div className="pd2">
                    <span className="label">CLASS TEACHER</span>
                    <h5 className="value">CLASS {teacher_info?.class_teacher || 'N/A'}</h5>
                  </div>
                </div>
                <div className="info">
                  <div className="pd1">
                    <span className="label">TEACHING CLASSES</span>
                    <h5 className="value">{teacher_info?.classes?.join(' | ') || 'N/A'}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right-details">
            <div className="contact-details">
              <div className="header">
                <h2 className="section-title">
                  <ContactMailIcon />
                  STUDENT ROSTER
                </h2>
              </div>
              <div className="stu-det">
              {students?.map((student) => (
                <div key={student.uniqueCode} className="student-info">
                  <h4 className="student-name">{student.name || 'N/A'}</h4>
                  <div className="det">
                    <span className="label">ROLL NO: {student.roll_number || 'N/A'}</span> <br />
                    <span className="label">CLASS: {student.standard || 'N/A'}</span>
                  </div>
                  <div className="det">
                    <span className="label">PARENT: {student.parent_name || 'N/A'}</span><br />
                    <span className="label">PHONE: {student.phone || 'N/A'}</span>
                  </div>
                  <div className="det">
                    <span className="label">GENDER: {student.gender || 'N/A'}</span>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
        <div className="address-details">
            <div className="header">
              <h2 className="section-title">
                <QuizIcon />
                STATISTICS OVERVIEW
              </h2>
            </div>
            <div className="statistics-overview">
              <div className="statistics-card">
                <span>TOTAL STUDENTS</span>
                <h3>{statistics?.total_students || 0}</h3>
              </div>
              <div className="statistics-card">
                <span>TOTAL EXAMS</span>
                <h3>{statistics?.total_exams || 0}</h3>
              </div>
              <div className="statistics-card completed">
                <span>COMPLETED EXAMS</span>
                <h3>{statistics?.completed_exams || 0}</h3>
              </div>
              <div className="statistics-card pending">
                <span>PENDING EXAMS</span>
                <h3>{statistics?.pending_exams || 0}</h3>
              </div>
            </div>

            <div className="header">
              <h2 className="section-title">
                <EventNoteIcon />
                RECENT EXAMINATIONS
              </h2>
            </div>
            <div className="main-exam-card">
            {exams?.slice(0, 5).map((exam) => (
              <Link 
                key={exam.exam_id}
                to={`/principal/exam-data?exam_id=${exam.exam_id}`} 
                style={{textDecoration:"none"}}
              >
                <div className="exam-card">
                  <div className="exam-header">
                    <h4>{exam.subject?.toUpperCase() || 'SUBJECT NOT SPECIFIED'}</h4>
                    <span className={`status ${exam.status?.toLowerCase() || ''}`}>
                      {exam.status || 'N/A'}
                    </span>
                  </div>
                  <div className="exam-details">
                    <span>CLASS: {exam.standard || 'N/A'}</span>
                    <span>DATE: {exam.scheduled_date || 'N/A'}</span>
                  </div>
                  <div className="exam-details">
                    <span>TIME: {exam.scheduled_time || 'N/A'}</span>
                    <span>DURATION: {exam.duration || 0} Min(S)</span>
                  </div>
                  <div className="exam-questions">
                    <span>
                      QUESTIONS: MCQ ({exam.question_count?.[0] || 0}) | DESCRIPTIVE ({exam.question_count?.[1] || 0})
                    </span>
                  </div>
                </div>
              </Link>
            ))}
            </div>
          </div>
      </div>
    </>
  );
};

export default TeacherData;
